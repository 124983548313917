/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-22 11:48:11
 * @modify date 2018-11-22 11:48:11
 * @desc [description]
*/

import React from 'react';

import IdeaIcon from '../../../assets/svg/idea.svg';
import TeamIcon from '../../../assets/svg/team.svg';
import ProductIcon from '../../../assets/svg/product.svg';
import LineOne from './assets/svg/line svg 1.svg';
import LineTwo from './assets/svg/line svg 2.svg';
import LineThree from './assets/svg/line svg 3.svg';
import LineVerticalOne from './assets/svg/vertical line 1.svg';
import LineVerticalTwo from './assets/svg/vertical line 2.svg';

import SVG from 'react-inlinesvg';

import './process.scss';

export const Process = () => (

    <div className="stepsWrapper">

        <StepInProcess icon={IdeaIcon} title={<React.Fragment><strong>your</strong> idea</React.Fragment>} line={LineOne} lineVertical={LineVerticalOne}/>
        <div className="text">Meets</div>
        <StepInProcess icon={TeamIcon} title={<React.Fragment><strong>our</strong> team</React.Fragment>} line={LineTwo} lineVertical={LineVerticalTwo} />
        <div className="text">Produces</div>
        <StepInProcess icon={ProductIcon} title={<React.Fragment><strong>great</strong> product</React.Fragment>} line={LineThree} lineVertical={null} />

    </div>

);

const StepInProcess = (props) => (

    <div className="step">
        <div>
            <div className="iconWrapper">
                <SVG src={props.icon}></SVG>
            </div>
            <div className="title">
                {props.title}
            </div>
        </div>
        <div className="horizontal">
            <SVG src={props.line} />
        </div>
        <div className="vertical">
            {props.lineVertical !== null ? <SVG src={props.lineVertical} /> : null}
        </div>
    </div>

);