/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-19 16:50:46
 * @modify date 2018-11-19 16:50:46
 * @desc [description]
*/

import React from 'react';

import WebsiteIcon from '../../../assets/svg/website.svg';
import DatabaseIcon from '../../../assets/svg/database.svg';
import Security from '../../../assets/svg/security.svg';

import SVG from 'react-inlinesvg';

import './services.scss';

export const Services = () => (
        
        <div className="serviceItemsWrapper">

            <ServiceItem icon={WebsiteIcon} title="Web &amp; Smartphone Development" description="Single Page Applications, Progressive Webapps, Native/Crossplatform apps." />
            <ServiceItem icon={Security} title="Cyber Audit" description="360 Degree threat analysis of people, system &amp; process of your Network and Data." />
            <ServiceItem icon={DatabaseIcon} title="Database Administration" description="Design, develop and always ensure efficient performance." />

        </div>

);

const ServiceItem = (props) => (

    <div className="serviceItem">

        <div className="iconWrapper">
            <SVG src={props.icon} ></SVG>
        </div>

        <h4>{ props.title }</h4>

        <div className="description">
            { props.description }
        </div>

    </div>

);
