/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-21 12:00:26
 * @modify date 2018-11-21 12:00:26
 * @desc [description]
*/

import React from 'react';
import Button from '../../../components/button/button';
import { Link } from 'react-router-dom';

import './about.scss';

export const About = () => (

    <React.Fragment>

        <div className="aboutWrapper">

            <p>
                Kimbu Tech is an international software company that provides IT solutions and outsourcing services. 
                Currently, it provides software development and maintenance to companies in Israel and United States 
                of America. We collaborate with KMC Educational Network - one of the leading educational institutions 
                of Nepal.
            </p>

        </div>

        <Link to="/about"><Button value="Know More About Us" className="primary large" /></Link>

    </React.Fragment>

);