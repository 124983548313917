/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-28 16:20:30
 * @modify date 2018-11-28 16:20:30
 * @desc [description]
*/

import React from 'react';

import Section from '../../components/section/section';
import Technologies from './technologies/technologies';

import PhotoCEO from './assets/images/karvika_thapa.jpg';
import PhotoChairperson from './assets/images/rameshwor_aryal.jpg';
import JobMelaPhoto from './assets/images/job_mela.jpg';
import GroupPhoto2 from './assets/images/group_photo_2.jpg';
import GroupPhoto3 from './assets/images/group_photo_3.jpg';
import SpeechImage from './assets/images/speech.jpg';

import './about.scss';

export default class About extends React.Component {

    render() {

        return(

            <React.Fragment>

                <Section title="Know More About Us" body={<AboutUs />} />
                <Section title="The CEO" body={
                    <InfoAboutTeam 
                        image={PhotoCEO}
                        name="Karvika Thapa" 
                        title="MBA - Founder and CEO of Kimbu Tech" 
                        info={`14 years experience in leading IT Projects in various industries like Healthcare, 
                        Education and Retail in United States. 
                        Karvika leads IT offshoring projects for clients from Israel and United States focusing 
                        on Web based application development, mobile app development, Data Solutions, SMS/IVR 
                        application development.`}
                    />
                }/>
                <Section title="Chairperson" body={
                    <InfoAboutTeam 
                        name="Rameshwor Aryal" 
                        title="Chairperson, Founder" 
                        image={PhotoChairperson}
                        info={`Mr. Rameshwor Aryal has been dedicated to providing educational facilities. He is the
                        Managing Director of KMC Educational Network which constitutes Kathmandu Model College, Himalaya College
                        of Engineering, Kathmandu Model Research Foundation and many other colleges and schools. Mr. Rameshwor Aryal
                        is excited about the use of technology in improving the education in Nepal.`}
                    />
                }/>
                <Section title="Why Kimbu Tech" body={
                    <div className="cardsWrapper">
                        <Card 
                            title="Mindful Of Culture" 
                            body="Over 14 years of experience in utilizing international offshore
                                resources in various managerial capacities, allows us to offer our clients superior offshoring solutions." 
                        />
                        <Card 
                            title="Freedom To Focus" 
                            body="By offering a skilled, reliable and scalable workforce, committed to the highest coding guidelines &amp; 
                                standards and timely project delivery, we will provide seamless integration with your local operations, as 
                                if both teams are working from the same premises." 
                        />
                        <Card 
                            title="Flexible Engagement Model" 
                            body="We follow an effective, customized process to build a team that can meet your exact needs and provide ongoing 
                                support to ensure streamlined operations and increased agility for your business." 
                        />
                    </div>
                } />
                <Section title="We Are Involved In" body={<OurInvolvement />} />
                <Section style={{backgroundColor: 'rgba(196,196,196,0.10)'}} title="Technologies We Work With" body={<Technologies />} />

            </React.Fragment>

        );

    }

}

const AboutUs = () => (

    <p>
        Kimbu Tech is an international software company that provides IT solutions and outsourcing services. Currently, 
        it provides software development and maintenance to companies in Israel and United States of America. We collaborate 
        with KMC Educational Network - one of the leading educational institutions of Nepal. Kimbu Tech is committed to 
        providing top-quality software development services through an experienced team of carefully recruited
        software experts based in Nepal. Our services include customized, enterprise grade products and services for startups, 
        mid-size businesses, and large enterprises. Whether you are looking to outsource a single role, a team in your business 
        or an entire business function, we can provide a tailored solution through our professional offshore teams.
    </p>

);

const Card = (props) => (

    <div className="card">
        <div className="title">
            {props.title}
        </div>
        <div className="body">
            {props.body}
        </div>
    </div>

);

const InfoAboutTeam = (props) => (

    <div className="aboutCEO">
        <div className="imgWrapper">
            <img src={props.image} alt=""/>
        </div>
        <div className="info">
            <h4>{`${props.name}, ${props.title}`}</h4>
            <p>{props.info}</p>
        </div>
    </div>

)

const OurInvolvement = () => (

    <div className="involvementWrapper">

        <div className="imgWrapper">
            <img src={JobMelaPhoto} alt=""/>
            <div className="infoWrapper">
                <div className="info">Career Opportunities</div>
            </div>
        </div>
        <div className="imgWrapper">
            <img src={GroupPhoto2} alt=""/>
            <div className="infoWrapper">
                <div className="info">Education</div>
            </div>
        </div>
        <div className="imgWrapper">
            <img src={SpeechImage} alt=""/>
            <div className="infoWrapper">
                <div className="info">Orientations</div>
                <p>Helping students define their educational purpose</p>
            </div>
        </div>
        <div className="imgWrapper">
            <img src={GroupPhoto3} alt=""/>
            <div className="infoWrapper">
                <div className="info">International Job Market</div>
                <p>Familiarizing students with high demand job opportunities and expectations</p>
            </div>
        </div>

    </div>

);