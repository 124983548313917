/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-26 16:49:09
 * @modify date 2018-11-26 16:49:09
 * @desc [description]
*/

import React from 'react';

import Section from '../../components/section/section';
import ContactForm from './contactform/contactform';
import SocialLinks from '../../components/footer/social/social';
import ContactDetails from '../../components/footer/contact/contact';

import './contact.scss';

export default class Contact extends React.Component {

    render() {

        return (

            <React.Fragment>

                <Section title="Get In Touch" body={<ContactSection />} />

            </React.Fragment>

        );

    }

}

const ContactSection = () => (

    <div className="contactSection">

        <div className="contactInfo">
            <ContactDetails />
            <SocialLinks />
        </div>

        <div className="line"></div>

        <ContactForm />

    </div>

);