/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 16:58:10
 * @modify date 2018-11-16 16:58:10
 * @desc [description]
*/

import React from 'react';

import { Switch, Route } from 'react-router-dom';

import Header from './components/header/header';
import Footer from './components/footer/footer';

import { Home } from './pages/home/home';
import Contact from './pages/contact/contact';
import About from './pages/about/about';
import Career from './pages/careers/career';
import Work from './pages/work/work';
import NotFound from './pages/notfound/notfound';
import Feedback from './components/feedback/feedback.container';
import Course from './pages/courses/course.container';

export const ModalContext = React.createContext({
	getQuoteModalVisibility: false,
	openGetQuoteModal: () => {},
	closeGetQuoteModal: () => {}
});

class App extends React.Component {

	constructor() {
		super();

		this.state = {
			getQuoteModalVisibility: false
		}

	}

	componentDidMount = () => {
        this._facebookMessenger();
    }

    _facebookMessenger =() => {
    
        window.fbAsyncInit = function() {
            window.FB.init({
            appId            : '1080703522103972',
            autoLogAppEvents : true,
            xfbml            : true,
            version          : 'v3.2'
            });
        };

        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

    }

	_openGetQuoteModal = () => {
		this.setState({
			getQuoteModalVisibility: true
		});
	}

	_closeGetQuoteModal  = () => {
		this.setState({
			getQuoteModalVisibility: false
		});
	}

	render() {
		return (

			<ModalContext.Provider value={{
				getQuoteModalVisibility: this.state.getQuoteModalVisibility,
				openGetQuoteModal: this._openGetQuoteModal,
				closeGetQuoteModal: this._closeGetQuoteModal
			}}>

				<div 
                    className="fb-customerchat"
                    attribution="setup_tool"
                    page_id="774645196259323"
                    theme_color="#34BFF3" 
				>
				</div>
				<Header />

				<Switch>
					<Route exact path="/" component={Home}/>
					<Route path="/contact" component={Contact} />
					<Route path="/about" component={About} />
					<Route path="/careers/:position?" component={Career} />
					<Route path="/work" component={Work} />
					<Route path="/courses/:course?" component={Course} />
					<Route component={NotFound} />
				</Switch>

				<Footer />

				<Feedback />

			</ModalContext.Provider>
				
		);
	}

}

export default App;
