/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-19 13:55:19
 * @modify date 2018-11-19 13:55:19
 * @desc [description]
*/

import React from 'react';

const services = () => (

    <div>
        <h4>Services</h4>
        <ul>
            <li>
                Web Development
            </li>
            <li>
                Smartphone Applications
            </li>
            <li>Cyber Audit</li>
            <li>
                Database Administration
            </li>
            <li>
                Server Development
            </li>
        </ul>
    </div>

);

export default services;