/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-04 12:12:55
 * @modify date 2018-12-04 12:12:55
 * @desc [description]
*/


import React from 'react';

import request from '../../../api/request';

import { Input } from '../../../components/form/forminputs';
import Button from '../../../components/button/button';
import Notifier from './../../../components/notifier/notifier';
import { LoaderBars } from './../../../components/loader/loader';

import './applyform.scss';

export default class ApplyForm extends React.Component {

    constructor() {

        super();

        this.state = {
            position:'',
            fullName:'',
            email:'',
            contact:'',
            cv:null,
            coverletter: null,
            validationErrors: {},
            notifier: {},
            requestBeingMade: false
        }

    }

    _validateFields = () => {

        let validationErrors = {};

        let emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        // fullname at least 2 characters
        if(this.state.fullName.length < 2) {
            validationErrors['fullName'] = "That's a very short name, please check again.";
        }

        // email must match patter
        if(!emailPattern.test(String(this.state.email).toLowerCase())) {
            validationErrors['email'] = "Your email address does not look valid";
        }

        // contact should be a number of ranging from 7 - 10
        if(isNaN(parseInt(this.state.contact)) || this.state.contact.length < 7 || this.state.length > 10) {
            validationErrors['contact'] = "Contact should only be a number, it should range from 7 to 10 digits";
        }

        // cv must be selected
        if(this.state.cv === null) {
            validationErrors['cv'] = "You might have forgotten to select your CV";
        }

        return validationErrors;

    }

    _updateValue = (event) => {

        let field = event.target;
        
        this.setState({
            [field.getAttribute('name')]: field.getAttribute('type') === 'file' ? field.files[0] : field.value
        });

    }

    _submitForm = (event) => {

        event.preventDefault();

        if(!this.state.requestBeingMade) {

            let validationErrors = this._validateFields();

            this.setState({
                validationErrors
            }, () => {

                if(Object.keys(validationErrors).length === 0) {

                    let data = new FormData();

                    Object.keys(this.state).forEach(key => { if(key !== 'validationErrors') data.append(key, this.state[key]) });

                    this.setState({
                        requestBeingMade: true
                    });

                    let apiCall = request('/mail/sendcv', 'post', data);

                    apiCall.then(response => {
                        this.setState({
                            notifier: {
                                type: "success",
                                message: response.data.message
                            },
                            requestBeingMade: false
                        });

                    })
                    .catch(error => {

                        this.setState({
                            notifier: {
                                type: "error",
                                message: "We could not receive your message"
                            },
                            requestBeingMade: false
                        });

                    });

                }

            });

        }

    }

    _removeNotification = () => {
        this.setState({
            notifier: {}
        });
    }

    componentDidMount = () => {
        this.setState({
            position: this.props.position
        });
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.position !== prevProps.position) {
            this.setState({
                position: this.props.position
            });
        }
    }

    render() {

        return(

            <React.Fragment>

                <form method="post">
            
                    <Input name="fullName" placeholder="Your Name" className={this.state.validationErrors.fullName ? 'error' : ''} onKeyUp={this._updateValue} />
                    {this.state.validationErrors.fullName ? <span className="error">{this.state.validationErrors.fullName}</span> : null}
                    <Input name="email" placeholder="Your Email" className={this.state.validationErrors.email ? 'error' : ''} onKeyUp={this._updateValue} />
                    {this.state.validationErrors.email ? <span className="error">{this.state.validationErrors.email}</span> : null}
                    <Input name="contact" placeholder="Your Phone Number" className={this.state.validationErrors.contact ? 'error' : ''} onKeyUp={this._updateValue} />
                    {this.state.validationErrors.contact ? <span className="error">{this.state.validationErrors.contact}</span> : null}
                    <label>Your CV</label>
                    <Input name="cv" type="file" className={this.state.validationErrors.cv ? 'error' : ''} onChange={this._updateValue} />
                    {this.state.validationErrors.cv ? <span className="error">{this.state.validationErrors.cv}</span> : null}
                    <label>Your Cover Letter</label>
                    <Input name="coverletter" type="file" onChange={this._updateValue} />
                    <Button 
                        onClick={this._submitForm} 
                        value={
                            this.state.requestBeingMade ? <LoaderBars />
                            : "Apply"
                        } 
                    />
                
                </form>

                { Object.keys(this.state.notifier).length !== 0 ? <Notifier removeNotification={this._removeNotification} type={this.state.notifier.type} notification={this.state.notifier.message} /> : null }

            </React.Fragment>

        );

    }

}