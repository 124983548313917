/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-19 16:40:11
 * @modify date 2018-11-19 16:40:11
 * @desc [description]
*/

import React from 'react';
import { PropTypes } from 'prop-types';

import './section.scss';


export default class Section extends React.Component {

    render() {

        return (

            <section className={this.props.standardHeight ? "standardHeight" : ""} style={this.props.style}>

                <h1>
                    {this.props.title}
                    <div className="gradient underline"></div>
                </h1>

                {this.props.body}

            </section>

        );

    }

}

Section.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func
    ]).isRequired,
    style: PropTypes.object,
    standardHeight: PropTypes.bool
}

Section.defaultProps = {
    standardHeight: true
}