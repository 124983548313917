/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-30 14:51:17
 * @modify date 2018-12-21 16:05:37
 * @desc [description]
*/


import React from 'react';

import SVG from 'react-inlinesvg';
import Button from '../../../components/button/button';
import Modal from '../../../components/modal/modal';
import ApplyForm from '../applyform/applyform';
import SharePosition from '../shareposition/SharePosition';

import { detectSwipe } from './../../../swipe';

import BackIcon from '../assets/svg/left-arrow.svg';

import request from '../../../api/request';

// import openPositions from './openpositions.json';

import './openpositions.scss';

export default class OpenPositions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // switching between open positions (tabbed navigation)
            activePositionIndex: 0,
            activePosition: props.position !== null ? props.position.replace(/_/g, ' ') : null,
            modalVisibility: false,
            modal: null,
            loading : true,
            openPositions : [ {
                "title": "No Position Available",
                "category": "",
                "numberOfOpenings": "",
                "jobLevel": "",
                "location": [],
                "salary": "",
                "educationLevel": "",
                "experienceRequiredInYears": "",
                "skills": [],
                "descriptions": [],
                "questionnaire": [],
                "requirements": [],
                "applicationDeadline": "",
                "active": true
            }]
        }
    }

    componentDidMount = () => {

        let openPositions = [];

        let apiCall =  request("data/openPosition", "get")

        apiCall.then(response => {

            openPositions = response.data;

            this.setState({
                openPositions
            })

        })
        .catch(error => {
            
            console.log(error);
            
        });

        let activePositionIndex = 0;

        if(this.props.position !== null) {
            Object.keys(this.refs).some(ref => {
                if(this.state.activePosition === this.refs[ref].dataset.position) {
                    activePositionIndex = parseInt(ref);
                    return true;
                }

                return false;
            });

            this.setState({
                activePositionIndex
            });

            this._scrollToOpenPositions();
        }
        else {
            this.setState({
                activePosition: this.state.activePositionIndex === 0 ? this.refs[1].dataset.position : this.refs[this.state.activePositionIndex].dataset.position
            });
        }

        this._toggleOpenPositionView(false);

        detectSwipe();

        window.addEventListener('resize', () => {
            this._toggleOpenPositionView(true);
        });  
        
        window.addEventListener('swipeLeft', this._hideOpenPositionViewOnSmallerScreens); 

    }

    componentWillUnmount = () => {
        
        window.removeEventListener('swipeLeft', this._hideOpenPositionViewOnSmallerScreens);

    }

    _hideOpenPositionViewOnSmallerScreens = () => {
        this.setState({
            activePositionIndex:0
        });
    }

    // open right side position view, or normal view
    _toggleOpenPositionView = (resized) => {
        
        if(resized) {
            // document resized, set active link to the current active link
            if(document.body.clientWidth > 750) {
                this.setState({
                    activePositionIndex: this.state.activePositionIndex
                });
            }
        }
        else{
            // document not resized, just check if screen is greater than or less than allocated width (750px)
            if(document.body.clientWidth > 750 && this.props.position === null) {
                this.setState({
                    activePositionIndex: 1
                });
            }
        }

    }

    _toggleActiveLink = (activePositionIndex) => {
        this.setState({
            activePositionIndex,
            activePosition: this.refs[activePositionIndex] ? this.refs[activePositionIndex].dataset.position : null,
            modalVisibility: false
        });
    }

    _openModal = (modal) => {
        this.setState({
            modalVisibility: true,
            modal
        });
    }

    _closeModal = () => {
        this.setState({
            modalVisibility: false
        });
    }

    _scrollToOpenPositions = () => {
        window.scrollTo({
            top: this.refs.openPositions.getBoundingClientRect().top - 50,
            left: 0, 
            behavior: 'smooth' 
        });
    }

    render() {

        const { openPositions } = this.state; 

        return(

            <React.Fragment>

                <div ref="openPositions" id="openPositionsTabContainer">
    
                    <div className="openPositionsList">
                        <h4>We're Hiring</h4>
                        
                        <div className="tabTitles">
                        
                            <ul>

                                {
                                    openPositions && openPositions.map((position, i) => (
                                        position.active ?
                                            <li key={i+1} data-tab-index={i+1} className={this.state.activePositionIndex === i+1 ? "active" : ""} onClick={() => this._toggleActiveLink(i+1)}>
                                                <span key={i+1} ref={i+1} data-position={position.title}>{position.title}</span>
                                            </li>
                                            :
                                            null
                                    ))
                                }

                                {/* <li data-tab-index="1" className={this.state.activePositionIndex === 1 ? "active" : ""} onClick={() => this._toggleActiveLink(1)}>
                                    <span ref="1" data-position="Firmware Engineer">Firmware Engineer</span>
                                </li>
                                <li data-tab-index="2" className={this.state.activePositionIndex === 2 ? "active" : ""} onClick={() => this._toggleActiveLink(2)}>
                                    <span ref="2" data-position="React Native Developer">React Native Developer</span>
                                </li> */}
                            </ul>
            
                        </div>
                    </div>
                                
                    <div ref="tabContentsContainer" className={`tabContentsContainer ${this.state.activePositionIndex !== 0 ? 'active' : ''}`}>

                        <Button onClick={() => this._toggleActiveLink(0)} value={<React.Fragment><SVG src={BackIcon} /> <span>All Open Positions</span></React.Fragment>} />
                    
                        {
                            openPositions && openPositions.map((position, i) => (
                                position.active ?
                                    <div key={i+1} className={`tabContent ${this.state.activePositionIndex === i+1 ? "active" : ""}`} data-content-of={i+1}>
                            
                                        <h2 className="title">{position.title}<span className="label">{position.numberOfOpenings}</span></h2>
                                        {

                                        position.skills && position.skills.length === 0 ?
                                            null
                                            :
                                            position.skills.map((skill, i) => (
                                                <span key={i} className="label primary">{skill}</span>
                                            ))
                                        }

                                        {
                                            position.jobLevel === '' ? null : <span className="label primary">{position.jobLevel}</span>
                                        }

                                        
                                        {/* mention experience here */}
                                        {/* <span className="label primary">2 Years</span> */}
                        
                                        <div className="jobDetails">
                                            {
                                                position.descriptions && position.descriptions.length !== 0 ?
                                                    <div className="descriptions">
                                                        <h4>Job Descriptions</h4>
                                                        <ol>
                                                            {
                                                                position.descriptions.map((description, i) => (
                                                                    <li key={i}>{description}</li>
                                                                ))
                                                            }
                                                        </ol>
                                                    </div>
                                                    : null
                                            }

                                            {
                                                position.requirements && position.requirements.length !== 0 ?
                                                    <div className="requirements">
                                                        <h4>Job Requirements</h4>
                                                        <ol>
                                                            {
                                                                position.requirements.map((requirement, i) => (
                                                                    <li key={i}>{requirement}</li>
                                                                ))
                                                            }
                                                        </ol>
                                                    </div>
                                                    : null
                                            }

                                            {/* <div className="requirements">
                                                <h4>Job Requirements</h4>
                                                <ol>
                                                    <li>Experience in IoT (MQTT).</li>
                                                    <li>Experience working with Bluetooth (BLE).</li>
                                                    <li>Knowledge of firmware and firmware development. (FOTA - Firmware On The Air)</li>
                                                </ol>
                                            </div> */}

                                        </div>
                                    
                                    </div>
                                    :
                                    null
                            ))
                        }

                        {/* <div className={`tabContent ${this.state.activePositionIndex === 1 ? "active" : ""}`} data-content-of="1"> */}
                        
                            {/* <h2 className="title">Firmware Engineer<span className="label">2</span></h2>
                            <span className="label primary">C/C++</span> */}
                            {/* mention experience here */}
                            {/* <span className="label primary">2 Years</span> */}
            
                            {/* <div className="jobDetails"> */}
                            
                                {/* <div className="descriptions">
                                    <h4>Job Descriptions</h4>
                                    <ol>
                                        <li>Build dynamic websites.</li>
                                        <li>Develop websites with great UI.</li>
                                        <li>Connect application with database.</li>
                                        <li>Collaborate with team members.</li>
                                        <li>Test and Quality Check.</li>
                                        <li>Deployment of application.</li>
                                    </ol>
                                </div> */}

                                {/* <div className="requirements">
                                    <h4>Job Requirements</h4>
                                    <ol>
                                        <li>Experience in IoT (MQTT).</li>
                                        <li>Experience working with Bluetooth (BLE).</li>
                                        <li>Knowledge of firmware and firmware development. (FOTA - Firmware On The Air)</li>
                                    </ol>
                                </div> */}

                            {/* </div> */}
                        
                        {/* </div> */}
                        
                        {/* <div className={`tabContent ${this.state.activePositionIndex === 2 ? "active" : ""}`} data-content-of="2">
                        
                            <h2 className="title">React Native Developer<span className="label">1</span></h2>
                            <span className="label primary">JavaScript</span> */}
                            {/* mention experience here (below) */}
                            {/* <span className="label primary">2 Years</span> */}
            
                            {/* <div className="jobDetails">
                            
                                <div className="descriptions">
                                    <h4>Job Descriptions</h4>
                                    <ol>
                                        <li>Develop applications for iOS &and; andriod.</li>
                                        <li>Deliver a great user interface and an even greater user experience.</li>
                                        <li>Collaborate with team members.</li>
                                        <li>Test and Quality Check.</li>
                                        <li>Deployment of application.</li>
                                    </ol>
                                </div>

                                <div className="requirements">
                                    <h4>Job Requirements</h4>
                                    <ol>
                                        <li>Should have experience in AppCenter.</li>
                                        <li>Knowledege of state management.</li>
                                        <li>Knowledge on deployment</li>
                                    </ol>  
                                </div>

                            </div>
                        
                        </div> */}
            
                        <div className="buttonsContainer">
                            <Button onClick={() => this._openModal("applyForm")} value="I'm fit for this" />
                            <Button onClick={() => this._openModal("sharePositions")} value="I know a friend" className="orange" />
                        </div>

                    </div>
        
                </div>

                <Modal 
                    title={<div className="titleWrapper">Applying For <br/><h3>{this.state.activePosition}</h3></div>} 
                    body={
                        this.state.modal === 'applyForm' ? (
                            <ApplyForm position={this.state.activePosition}/>
                        ) : <SharePosition activePosition={this.state.activePosition} activePositionIndex={this.state.activePositionIndex} />
                    }
                    modalVisibility={this.state.modalVisibility}
                    closeModal={this._closeModal}
                />

            </React.Fragment>

        );

    }

}
