/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-05-12 15:29:09
 * @modify date 2019-05-12 15:29:09
 * @desc [description]
*/

import React from 'react';

import { LoaderBars } from './../loader/loader';

import './feedback.scss';

export default class Feedback extends React.Component {

    constructor() {

        super();

        this.state = {
            feedbackDetails: {
                name: '',
                subject: '',
                email: '',
                feedback: ''
            },
            open: false,
        }

    }

    _updateFormFields = (field, value) => {
        let {feedbackDetails} = this.state;

        feedbackDetails[field] = value;

        this.setState({
            feedbackDetails
        });

    }

    _submitFeedback = () => {
        this.props._submitFeedback(this.state.feedbackDetails);
    }

    _toggleForm = () => {
        this.setState({
            open: !this.state.open
        });
    }

    render() {

        return (
            <>
                <button className="feedback-btn" onClick={this._toggleForm}>Feedback</button>
                
                <div className={`feedback-form-wrapper ${this.state.open ? 'open' : ''}`}>
                    <h4>
                        Help us improve.
                        <div className="close" onClick={this._toggleForm}>&times;</div>
                    </h4>
                    <form>
                        <div className="input-group">
                            <label htmlFor="name">Name (Optional)</label>
                            <input onChange={e => this._updateFormFields('name', e.target.value)} id="name" type="text" name="name" placeholder="" />
                        </div>
                        <div className="input-group">
                            <label htmlFor="email">Email (Optional)</label>
                            <input onChange={e => this._updateFormFields('email', e.target.value)} id="email" type="text" name="email" placeholder="" />
                        </div>
                        <div className="input-group">
                            <label htmlFor="subject">Subject (Required)</label>
                            <input required onChange={e => this._updateFormFields('subject', e.target.value)} id="subject" type="text" name="subject" placeholder="Animathon, Web application, Mobile Applications, etc." />
                        </div>
                        <div className="input-group">
                            <label htmlFor="feedback">Feedback (Required)</label>
                            <textarea required onChange={e => this._updateFormFields('feedback', e.target.value)} id="feedback" name="feedback" cols="30" rows="9"></textarea>
                        </div>

                        <button type="button" onClick={this._submitFeedback}>
                            {
                                this.props.submitting ? <LoaderBars /> : "Submit Feedback"
                            }
                        </button>

                    </form>
                </div>
            </>
        )

    }

}