/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-30 11:35:37
 * @modify date 2018-11-30 11:35:37
 * @desc [description]
*/

import React from 'react';

import { withRouter } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import Section from '../../components/section/section';
import OpenPositions from './openpositions/openpositions';

import ExploreIcon from './assets/svg/flag.svg';
import InnovationIcon from './assets/svg/lightbulb.svg';
import GrowthIcon from './assets/svg/plant.svg';

import './career.scss';

class Career extends React.Component {

    render() {

        return(

            <React.Fragment>

                <Section title="Why Choose US ?" body={<p>{WhyChooseUs}</p>} />
                <Section style={{backgroundColor:'rgba(196,196,196,0.10)'}}
                    title="Our Culture" 
                    body={
                        <div className="valuesWrapper">
                            <Value title="Growth and Career Development" description="Grow with us to new heights in your career with state of the art technologies." icon={GrowthIcon}/>
                            <Value title="Explorative Environment" description="Work with wide range of technologies, find your fit, excel to new heights." icon={ExploreIcon}/>
                            <Value title="Focus On Innovation" description="Tackle local and global issues with your ideas and expertise." icon={InnovationIcon}/>
                        </div>
                    }
                />
                <Section title="Open Positions" body={<OpenPositions position={this.props.match.params.position || null} />} />

            </React.Fragment>

        );

    }

}

const Value = (props) => (

    <div className="value">

        <div className="iconWrapper">
            <SVG src={props.icon} ></SVG>
        </div>

        <h4>{ props.title }</h4>

        <div className="description">
            { props.description }
        </div>

    </div>

);

const WhyChooseUs = `Kimbu Tech is committed to helping its client to reach their
                    goals. With several years of experience in the IT field we strive
                    hard to provide the true value solutions for all kinds of IT and
                    technology related needs. We choose clients that share in our
                    values. Once you start working with us you become the part of
                    our family. We believe in creating an environment full of
                    talented, enthusiastic people who enjoy coming to work every
                    day so everyone working with us does their work very
                    passionately. No matter the budget, we value our clients the
                    most and will do anything to ensure their happiness. You will
                    not only benefit from high quality IT service but can also get in
                    touch with our experts.`;

                    
export default withRouter(Career);