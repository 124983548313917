/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-12 11:51:20
 * @modify date 2018-12-12 11:51:20
 * @desc [description]
*/

import axios from 'axios';

// const ROOT_URL = "https://kimbutech.com/";
const ROOT_URL = "";
// const ROOT_URL = "http://192.168.0.249:8080/";

const request = (url, method = "get", data = {}) => {

    url = ROOT_URL + url;

    switch(method) {

        case "get":

            return axios.get(url);

        case "post":

            return axios.post(url, data);

        default:
            
            

    }

}

export default request;