/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-26 16:01:14
 * @modify date 2018-11-26 16:01:14
 * @desc [description]
*/

import React from 'react';

import './forminputs.scss';

export const Input = (props) => (

    <input 
        name={props.name} 
        type={props.type} 
        value={props.value} 
        className={props.className} 
        placeholder={props.placeholder}
        onKeyUp={props.onKeyUp} 
        onChange={props.onChange}
    />

);

export const TextArea = (props) => (

    <textarea name={props.name} type="text" className={props.className} placeholder={props.placeholder} onKeyUp={props.onKeyUp} >
        {props.value}
    </textarea>

);

export class CheckBox extends React.Component {

    constructor() {

        super();

        this.state = {
            checked: false,
        }

    }

    _handleOnChange = (event) => {
        this.setState({
            checked: event.target.checked
        });
    }

    _changeCheckBoxStatus = (callback = null) => {

        this.setState({
            checked: !this.state.checked
        }, callback);

    }

    render() {

        return (

            <div 
                className={`checkboxWrapper ${this.state.checked ? 'checked' : ''}`} 
                onClick={(event) => {
                        
                    if(this.props.onChange) {
                        this._changeCheckBoxStatus(() => {
                            this.props.onChange(this.refs.input);
                        });
                    }
                    else{
                        this._changeCheckBoxStatus();
                    }
                }}
            >
                <label>{this.props.label}</label>
                <input 
                    ref="input" 
                    type="checkbox"
                    onClick={event => event.stopPropagation()} 
                    name={this.props.name} 
                    id={this.props.id} 
                    value={this.props.value}
                    onChange={(event) => {

                        event.stopPropagation();

                        this._handleOnChange(event);
                        
                        if(this.props.onChange) {
                            this.props.onChange(event);
                        }

                    }} 
                    checked={this.state.checked}/>
            </div>

        );

    }

}

export class RadioButton extends React.Component {

    constructor() {

        super();

        this.state = {
            checked: false,
        }

    }

    _handleOnChange = event => {
        this.setState({
            checked: event.target.checked
        });
    }

    _changeCheckBoxStatus = () => {
        this.setState({
            checked: !this.state.checked
        });
    }

    render() {

        return (

            <div className={`checkboxWrapper ${this.state.checked ? 'checked' : ''}`} onClick={this._changeCheckBoxStatus}>
                <label>{this.props.label}</label>
                <input type="radio" name={this.props.name} id={this.props.id} onChange={this._handleOnChange} checked={this.state.checked}/>
            </div>

        );

    }

}

export class RangePicker extends React.Component {

    constructor() {

        super();

        this.state = {
            value:2
        }

    }

    _handleOnChange = (value) => {
        this.setState({value});
    }

    render() {

        return(
            <input type="range" name={this.props.name} id={this.props.id} min={this.props.min} max={this.props.max} value={this.state.value}/>
        );

    }

}

Input.defaultProps= {
    type: "text"
}