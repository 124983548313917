/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:17:41
 * @modify date 2018-11-16 12:17:41
 * @desc [description]
*/

import React from 'react';
import "./button.scss";

import PropTypes from 'prop-types';

const button = (props) => (
    <button 
        onClick={props.onClick} 
        className={props.className}
        type={props.type}
    >
        {props.value}
    </button>
);

button.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]).isRequired
}

button.defaultProps = {
    className: "primary",
}

export default button;

