/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-07 12:06:00
 * @modify date 2018-12-07 12:06:00
 * @desc [description]
*/

import React from 'react';

import { withRouter } from 'react-router-dom';

class ScrollToTop extends React.Component {

    componentDidUpdate(prevProps) {

        if(this.props.location !== prevProps.location) {
            window.scrollTo({
                top:0,
                left: 0,
                behavior:'smooth'
            });
        }

    }

    render() {

        return (
            this.props.children
        );

    }

}

export default withRouter(ScrollToTop);