/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-03 16:30:25
 * @modify date 2018-12-21 10:22:16
 * @desc [description]
*/

import React from 'react';

import Section from '../../components/section/section';

import IVFImage from './assets/images/ivf screenshot.png';
import KMCNetworkLogo from './assets/images/kmclogo.png';
import HCOELogo from './assets/images/hcoe.png';
import NeutrosysLogo from './assets/images/neutrosys.png';
import ElsightLogo from './assets/images/elsight.png';
import HashUnitedLogo from './assets/images/hashunitedlogo.svg';
import KavadevLogo from './assets/images/kavadev.png';
import SkylineLogo from './assets/images/skyline logo.png';
import TomiaLogo from './assets/images/tomia.png';
import KimbuJobs from './assets/images/kimbu_jobs_logo.png';

import './work.scss';

export default class Contact extends React.Component {

    render() {

        return (

            <React.Fragment>

                <Section title="Some of Our Clients"
                        body={
                            <React.Fragment>

                                <Client 
                                    image={TomiaLogo} 
                                    client="Tomia Global"
                                    message={
                                        `Local Authorized Partners for Starhome Mach GmbH.`
                                    }
                                    services={['Visit Website']}
                                    link="https://www.tomiaglobal.com/"
                                    includeShadow={false}
                                />

                                <Client 
                                    image={KimbuJobs} 
                                    client="Kimbu Jobs"
                                    message={
                                        `A complete job Portal in Nepal. We provide career opportunities  for job seekers and hiring managers.`
                                    }
                                    services={['Visit Website']}
                                    link="https://www.kimbujobs.com"
                                    includeShadow={false}
                                />

                                <Client 
                                    image={KavadevLogo} 
                                    client="Kavadev"
                                    message={
                                        `Local Authorized Partners.`
                                    }
                                    services={['Visit Website']}
                                    link="https://kavadev.com/"
                                    includeShadow={false}
                                />
                                <Client 
                                    image={HashUnitedLogo} 
                                    client="Hash United"
                                    message={
                                        ``
                                    }
                                    services={['Visit Website']}
                                    link="https://hashunited.com/"
                                    includeShadow={false}
                                />  
                                <Client 
                                    image={ElsightLogo} 
                                    client="elsight"
                                    message={
                                        `Elsight Limited develops mobile security and surveillance solutions. The Company offers video and data capturing, 
                                        recording, and transmission for various real-tactical security and surveillance purposes for security solutions, 
                                        detection, verification, investigation, and tactical operations. Elsight serves customers worldwide.`
                                    }
                                    services={['Visit Website']}
                                    link="https://elsight.com/"
                                    includeShadow={false}
                                />  
                                <Client 
                                    image={NeutrosysLogo} 
                                    client="Neutrosys IT Services"
                                    message={
                                        `We are very thankful to have formed excellent partnerships with KimbuTech, a Nepal based IT company. 
                                        KimbuTech is everything you want in a business partner. They take the time to understand what your 
                                        business needs are, and the costumer services and the end results are always top notch. We appreciate 
                                        their attention to details and creative approaches. We developed a great partnership and we are very 
                                        excited to work with this company. We’ve formed more than just working relationships with this company 
                                        - we have formed true friendships.`
                                    }
                                    services={['Partner', 'Visit Website']}
                                    link="http://www.neutrosys.com/"
                                    includeShadow={false}
                                />  

                                <Client 
                                    image={SkylineLogo} 
                                    client="Skyline Architects Pvt. Ltd."
                                    message={
                                        `We are very much impressed by the work accomplished by Kimbu Tech with in the limited time. 
                                        Their work has helped us in growth of our company and we promise to work together for future projects too.`
                                    }
                                    services={['Visit Website']}
                                    link="http://www.skylinearchitect.com.np/"
                                    includeShadow={false}
                                />  

                                <Client 
                                    image={IVFImage} 
                                    client="IVF Nepal"
                                    message={
                                        `Choosing Kimbu tech for was the best decision for creating website for our website. 
                                        They have got the great designers and passionate team .`
                                    }
                                    services={['Visit Website']}
                                    link="https://ivfnepal.com"
                                    includeShadow={true}
                                />

                                <Client 
                                    image={KMCNetworkLogo} 
                                    client="KMC Educational Network"
                                    message={
                                        `We are so much satisfied that we are ready to tie up with each and every project together. 
                                        It has been established within the short time.`
                                    }
                                    services={['Website', 'IT Consultants']}
                                    includeShadow={false}
                                />

                                <Client 
                                    image={HCOELogo} 
                                    client="Himalaya College of Engineering"
                                    message={
                                        `Kimbu Tech is one of the best IT solution in Nepal and we are grateful to be a part of this organization. 
                                        We wish them for great future ahead and would like to involve in every projects and work as a team.`
                                    }
                                    services={['Website', 'IT Consultants']}
                                    includeShadow={false}
                                />
                                
                            </React.Fragment>
                        } />

            </React.Fragment>

        );

    }

}

const Client = (props) => (

    <div className="clientWrapper">
    
        <div className="imageWrapper">
            <img src={props.image} alt="" className={props.includeShadow ? 'boxShadow' : null}/>
        </div>

        <div className="clientInfo">

            <h3>{props.client}</h3>
            <p>{props.message}</p>

            {props.services && props.services.map((service, index) => (
                <div key={index} className="service">
                    {
                        props.link !== undefined && props.link !== '' ? <a target="_blank" href={props.link} rel="noopener noreferrer">{service}</a> : service
                    }
                </div>
            ))}

        </div>

    </div>

)
