/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-05-12 15:23:16
 * @modify date 2019-05-12 15:23:16
 * @desc [description]
*/

import React from 'react';

import FeedbackForm from './feedback.js';
import request from '../../api/request';

export default class Feedback extends React.Component {

    constructor() {
        super();

        this.state = {
            submitting: false
        }

    }

    _submitFeedback = (feedback) => {

        this.setState({
            submitting: true
        });

        request('mail/feedback', 'post', feedback)
        .then(res => {
            this.setState({
                submitting: false
            });
            alert("We have received your feedback. Thank you !")
        })
        .catch(err => {

            let msg = "Sorry could not post your feedback, please try again.";

            if(err.response && err.response.data.message) {
                err.response.data.message.map(message => {
                    return msg += message.msg;
                });
            }

            this.setState({
                submitting: false
            });
            
            alert(msg);
        });
    }

    render() {

        return (

            <FeedbackForm _submitFeedback={this._submitFeedback} submitting={this.state.submitting} />

        );

    }

}