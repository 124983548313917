/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-05-14 12:08:27
 * @modify date 2019-05-14 12:08:27
 * @desc [description]
 */

import React from 'react';
import Section from '../../components/section/section';

import LOADER from '../../components/loader/loader.gif';

import './course.scss';
import { Link } from 'react-router-dom';

const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export default class Courses extends React.Component {

    render() {

        if(this.props.loading) {
            return <Section title="Courses We Offer" body={<img src={LOADER} alt="Loading ..." />} />
        }

        return (

            <React.Fragment>

                <Section title="Courses We Offer" body={
                    this.props.courses && this.props.courses.length === 0 ?
                        <p className='message failure'>We faced some problem fetching courses. Please try again.</p>
                        :
                        <CoursesWeOffer courses={this.props.courses} />
                } />

            </React.Fragment>

        );

    }

}

const CoursesWeOffer = ({courses}) => (

    <div className="courses">

        {
            courses && courses.map((course, i) => <CourseCard course={course} key={i}/>)
        }

    </div>

)

const CourseCard = ({course}) => {

    let classDate = new Date(course.classStatus.classAt);
    let month = MONTHS[classDate.getMonth()];
    let date = classDate.getDate();
    date = date.toString().length === 1 ? `0${date}`: date;

    let displayDate = course.classStatus.classAt ? `${month} ${date}` : 'N/A';

    let path = course.title.toLowerCase();
    path = path.replace(" ", "_");

    return (
        
        <div className={`course card ${course.classStatus.seatAvailable ? 'active' : 'dormant'}`} >
            <Link to={{
                pathname: `/courses/${path}`,
                state: {
                    courseId: course.id
                }
            }}>
                <h4>{course.title}</h4>
                <div className="date">{displayDate}.</div>
                <p>{course.shortDescription}</p>
                <span>{course.classStatus.seatAvailable ? 'Registration Open. Seats Available' : 'Registration Soon.'}</span>
            </Link>
        </div>

    )
}   