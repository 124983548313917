/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-05-14 13:08:54
 * @modify date 2019-05-14 13:08:54
 * @desc [description]
*/

import React from 'react';
import Courses from './courses';
import CourseIndividual from './course-individual/courseindividual.container';

import request from '../../api/request';

export default class Course extends React.Component {

    constructor() {

        super();

        this.state = {

            courses: [], 
            loading: true,
            

        }

    }

    componentDidMount() {

        if(this.props.match.params.course === undefined) {

            request('data/course/multiple/0')
            .then(res => {

                this.setState({
                    courses: res.data,
                    loading: false
                });

            })
            .catch(err => {
                console.log(err);
                this.setState({
                    loading: false
                });
            });

        }

    }

    render() {

        const COURSE_ID = this.props.location && this.props.location.state ? this.props.location.state.courseId : null;

        return (
            
            this.props.match && this.props.match.params.course === undefined ? 
                <Courses courses={this.state.courses} loading={this.state.loading} />
                :
                <CourseIndividual course={this.props.match.params.course} courseId={COURSE_ID} />
            
        );

    }

}