/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 16:51:08
 * @modify date 2018-12-21 10:21:58
 * @desc [description]
*/

import React from 'react';
import './home.scss';

import Button from '../../components/button/button';
import Section from '../../components/section/section';
import { Services  } from './services/services';
import { About } from './aboutus/about';
import { Process } from './process/process';
import { ClientSection as Clients } from './clients/clients';
import TechnologiesSlider from './technologies/technologies';

import { ModalContext } from './../../App';

export class Home extends React.Component {

    render() {

        return (

            <React.Fragment>

                <Section title="We Specialize In" body={<Services />} />
                <Section title="Technologies We Work With" body={<TechnologiesSlider />} />
                <Section title="About Kimbu Tech" style={{backgroundColor:'rgba(196,196,196,0.07)'}} body={<About />} />
                <Section title="The Process Is Very Simple" body={<Process />} />
                <Section title="Some of Our Clients" body={<Clients />} />

            </React.Fragment>

        );

    }

}

export const HomeTitle = () => (

    <section className="titleSection">

        <h1>Making Technology Accessible</h1>

        <div>
            <span>Get Quote For</span>
            <div>
                <ModalContext.Consumer>
                    {
                        ({openGetQuoteModal}) => (
                            <React.Fragment>
                                <Button value="WEB" onClick={openGetQuoteModal}/>
                                <Button className="secondary" value="MOBILE" onClick={openGetQuoteModal}/>
                            </React.Fragment>
                        )
                    }
                </ModalContext.Consumer>
            </div>
        </div>

    </section>

);