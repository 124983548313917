/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-23 16:46:36
 * @modify date 2018-11-23 16:46:36
 * @desc [description]
*/

import React from 'react';
import Button from '../../button/button';

import './message.scss';

import backgroundImage from './assets/images/adult-airport-backpack.jpg';
import { ModalContext } from '../../../App';
import { Link } from 'react-router-dom';

const Message = () => (

    <React.Fragment>

        <div className="messageWrapper">

            <div className="overlay"></div>

            <img src={backgroundImage} alt=""/>
            
            <div className="message">
                <h1>Next Step In Your<br/>Progress</h1>
            </div>

            <div className="btnWrapper">
                <ModalContext.Consumer>
                    {
                        ({openGetQuoteModal}) => (
                            <Button value="Get Quote" className="primary" onClick={openGetQuoteModal} />
                        )
                    }
                </ModalContext.Consumer>
                
                <Link to="/contact"><Button value="Reach Out" className="secondary" /></Link>
            </div>

        </div>

    </React.Fragment>

);

export default Message;