/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 13:36:07
 * @modify date 2018-11-16 13:36:07
 * @desc [description]
*/

import React from 'react';
import { Switch } from 'react-router-dom';

import Navbar from './navbar/navbar';
import { HomeTitle } from '../../pages/home/home';
import { QuoteFormInsideModal } from '../../pages/quote/quoteform/quoteform';

import CallIcon from '../../assets/svg/call-answer.svg';
import Envelope from '../../assets/svg/envelope.svg';

import background from './assets/images/cover_image_1.jpg';
// import CoverSVG from './assets/svg/cover_shape.svg';

import './header.scss';

export default class Header extends React.Component {

    render() {

        return (

            <React.Fragment>

                <div className="header">

                    <div className="topBar">
                        <div>
                            <img src={Envelope} alt="Call"/>
                            <span><a href="mailto:info@kimbutech.com">info@kimbutech.com</a></span>
                        </div>
                        <div>
                            <img src={CallIcon} alt="Mail"/>
                            <span><a href="tel:+977 01 4215092">+9774215092</a></span>
                        </div>
                    </div>

                    <Navbar />

                    <div className="skewedSection">

                        <div className="overlay gradient"></div>

                        <img src={background} alt=""/>
                        {/* <img src={CoverSVG} alt=""/> */}
                        
                        <Switch>
                            <HomeTitle exact path="/" />
                            <CommonHeader title="Let's Build Something Great Together" path="/contact"/>
                            <CommonHeader title="QUALITY . HONESTY . FUN" path="/about"/>
                            <CommonHeader title="Let's Grow Together" path="/careers" />
                            <CommonHeader title="We Can Do So Much Together" path="/work" />
                            <CommonHeader title="Shaping Better Education" path="/courses/:course?" />
                        </Switch>


                    </div>

                </div>

                <QuoteFormInsideModal />

            </React.Fragment>
        
        );

    }

}

const CommonHeader = (props) => (

    <section className="titleSection">

        <h1>{props.title}</h1>

    </section>

);