/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:49:05
 * @modify date 2018-11-16 12:49:05
 * @desc [description]
*/

import React from 'react';

import Button from './../../button/button';
import Logo from '../../../assets/svg/logo.svg';

import { detectSwipe } from '../../../swipe';

import { Link, NavLink } from 'react-router-dom';
import "./navbar.scss";

import { ModalContext } from './../../../App';

export default class Navbar extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            sideBarOpen: false,
            modalVisibility: false
        }

    }

    // toggle sidebar
    // eslint-disable-next-line
    _toggleSidebar = (action = null) => {
        this.setState({
            sideBarOpen: action === null ? !this.state.sideBarOpen : action === "show" ? true : false 
        });
    }

    _openModal = () => {
        this.setState({
            modalVisibility: true
        });
    }

    componentDidMount = () => {

        detectSwipe();

        window.addEventListener('swipeLeft', () => {
            this._toggleSidebar("hide");
        });

        window.addEventListener('swipeRight', () => {
            this._toggleSidebar("show");
        });

        document.addEventListener('scroll', (event) => {
            if(this.state.sideBarOpen) {
                this._toggleSidebar('hide');
            }
        });

    }

    render() {

        return (

            <div className="navContainer">
        
                <div id="hambrgr" onClick={() => this._toggleSidebar(null) }>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
    
                <div className="logoContainer">
                    <Link to="/"><img src={Logo} alt="Kimbu Tech" /></Link>
                </div>
    
                <nav className={ this.state.sideBarOpen ? "visible": "" }>
                    <ul>
                        <li onClick={() => this._toggleSidebar('hide')}>
                            <NavLink exact to="/">Home</NavLink>
                        </li>
                        <li onClick={() => this._toggleSidebar('hide')}>
                            <NavLink to="/work">Work</NavLink>
                        </li>
                        <li onClick={() => this._toggleSidebar('hide')}>
                            <NavLink to="/about">About</NavLink>
                        </li>
                        <li onClick={() => this._toggleSidebar('hide')}>
                            <NavLink to="/careers">Careers</NavLink>
                        </li>
                        <li onClick={() => this._toggleSidebar('hide')}>
                            <NavLink to="/contact">Contact</NavLink>
                        </li>
                        <li onClick={() => this._toggleSidebar('hide')}>
                            <NavLink to="/courses">Courses</NavLink>
                        </li>
                    </ul> 
                </nav>
            
                <ModalContext.Consumer>
                    {
                        ({openGetQuoteModal}) => {
                            return (
                                <Button onClick={openGetQuoteModal} value="Quote" />
                            )
                        }
                    }
                </ModalContext.Consumer>
    
            </div>

        );

    }

}