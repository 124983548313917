/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-16 10:37:32
 * @modify date 2018-12-16 10:37:32
 * @desc [description]
*/

import React from 'react';

import Slider from 'react-slick';

import AndroidLogo from '../../about/technologies/assets/svg/android.svg';
import AngularJSLogo from '../../about/technologies/assets/svg/AngularJS-large.svg';
import ReactJSLogo from '../../about/technologies/assets/svg/react.svg';
import NodeJSLogo from '../../about/technologies/assets/svg/nodejs.svg';
import VueJSLogo from '../../about/technologies/assets/svg/vue js.svg';

import HTML5Logo from '../../about/technologies/assets/svg/html 5.svg';
import CSS3Logo from '../../about/technologies/assets/svg/css3.svg';
import JavaScriptLogo from '../../about/technologies/assets/svg/js.svg'
import ReactLogo from '../../about/technologies/assets/svg/react.svg';
import AngularLogo from '../../about/technologies/assets/svg/AngularJS-large.svg';
import JqueryLogo from '../../about/technologies/assets/svg/jquery.svg';
import VueLogo from '../../about/technologies/assets/svg/vue js.svg';
import SassLogo from '../../about/technologies/assets/svg/sass.svg';
import LessLogo from '../../about/technologies/assets/svg/less_logo.svg';

import Nodejs from '../../about/technologies/assets/svg/nodejs.svg';
import Php from '../../about/technologies/assets/svg/php.png';
import Python from '../../about/technologies/assets/svg/python.svg';
import Ruby from '../../about/technologies/assets/svg/ruby.png';
import RubyonRails from '../../about/technologies/assets/svg/Ruby-on-rails.png';
import Java from '../../about/technologies/assets/svg/java.png';
import Cpp from '../../about/technologies/assets/svg/ISO_C++_Logo.svg';
import Csharp from '../../about/technologies/assets/svg/csharp.png';

import Reactnative from '../../about/technologies/assets/svg/react-native.png';
import NativeScript from '../../about/technologies/assets/svg/native-script.png';
import Swift from '../../about/technologies/assets/svg/swift.png';

import PostgreSQL from '../../about/technologies/assets/svg/Postgresql.svg';
import Mysql from '../../about/technologies/assets/svg/mysql.svg';
import MongoDB from '../../about/technologies/assets/svg/MongoDB-Logo.svg';
import Neo4j from '../../about/technologies/assets/svg/neo4j.png';
import ElasticEngine from '../../about/technologies/assets/svg/elasticsearchengine.png';
import CouchDB from '../../about/technologies/assets/svg/couchdb.png';
import MSsql from '../../about/technologies/assets/svg/mssql.png';
import Oracle from '../../about/technologies/assets/svg/Oracle_logo.svg';

import Linux from '../../about/technologies/assets/svg/linux.png';
import AWS from '../../about/technologies/assets/svg/AWS.png';
import GoogleCloud from '../../about/technologies/assets/svg/google cloud.png';
import Docker from '../../about/technologies/assets/svg/docker.svg';
import Azure from '../../about/technologies/assets/svg/azure.svg';

import './technologies.scss';

const sliderSettings = {

    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false
    ,
    dots: false,
    autoplay: true,
    speed: 150,
    infinite: true,
    centerMode: false,
    variableWidth: true

}

const technologies = [

    {
        name: 'Android',
        logo:AndroidLogo
    }, 
    {
        name: 'React JS',
        logo: ReactJSLogo
    },
    {
        name: 'Node JS',
        logo:NodeJSLogo
    },
    {
        name: 'VUE JS',
        logo: VueJSLogo
    },
    {
        name: 'Python',
        logo: Python
    },
    {
        name: 'PHP',
        logo: Php
    },
    {
        name: 'Ruby',
        logo: Ruby
    },
    {
        name: 'Java',
        logo: Java
    },
    {
        name: 'React Native',
        logo: Reactnative
    },
    {
        name: 'Native Script',
        logo: NativeScript
    },
    {
        name: 'Swift',
        logo: Swift
    },
    {
        name: 'Mongo DB',
        logo: MongoDB
    },
    {
        name: 'MySql',
        logo: Mysql
    },
    {
        name: 'Oracle',
        logo: Oracle
    },
    {
        name: 'Ruby',
        logo: Ruby
    },
    {
        name: 'HTML5',
        logo: HTML5Logo
    },
    {
        name: 'CSS3',
        logo: CSS3Logo
    },
    {
        name: 'Sass',
        logo: SassLogo
    },
    {
        name: 'Less',
        logo: LessLogo
    },
    {
        name: 'JQuery',
        logo: JqueryLogo
    },
    {
        name: 'Docker',
        logo: Docker
    },
    {
        name: 'AWS',
        logo: AWS
    },
    {
        name: 'Azure',
        logo: Azure
    },
    {
        name: 'Java',
        logo: Java
    },
    {
        name: 'Google Cloud',
        logo: GoogleCloud
    }


];

const TechnologiesSlider = () => (

    <div className="slider">
        <Slider {...sliderSettings} >

            {
                technologies && technologies.map((technology, index) => (
                    <div className="iconWrapper" key={index}>
                        <img src={technology.logo} alt="" />
                        <h5>{technology.name}</h5>
                    </div>
                ))
            }

        </Slider>
    </div>

);

export default TechnologiesSlider;