/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-19 10:40:10
 * @modify date 2018-12-19 10:40:10
 * @desc [description]
*/

import React from 'react';

import './notfound.scss';

const NotFound = () => (

    <div className="container">

        <h1>4<span class="orange">0</span>4</h1>
        <div className="message">
            We cannot find the page you are requesting. Please check for spelling errors or if someone gave you 
            this link its possible that we have removed it.
        </div>

    </div>

);

export default NotFound;