/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-05 11:44:41
 * @modify date 2018-12-05 11:44:41
 * @desc [description]
*/

import React from 'react';

import Notifier from '../../../components/notifier/notifier';
import Modal from '../../../components/modal/modal';
import Button from '../../../components/button/button';
import {TextArea, Input, CheckBox } from '../../../components/form/forminputs';

import { LoaderBars } from './../../../components/loader/loader';

import './quoteform.scss';
import { ModalContext } from '../../../App';

import SVG from 'react-inlinesvg';
import WarningIcon from './assets/svg/exclamation-mark.svg';

import request from '../../../api/request';

export default class QuoteForm extends React.Component {

    constructor() {
        super();

        this.state = {
            activeTabPosition: 1,
            fullName:'',
            address:'',
            email:'',
            contact:'',
            numberOfDevelopers:'',
            descriptions:'',
            requirements:'',
            technologies:'',
            platform:[],
            model:[],
            validationErrors: {},
            notifier: {},
            requestBeingMade: false
        }

    }

    _validateFields = () => {

        let validationErrors = {};

        let emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        // fullname at least 2 characters
        if(this.state.fullName.length < 2) {
            validationErrors['fullName'] = "That's a very short name, please check again.";
        }

        // email must match patter
        if(!emailPattern.test(String(this.state.email).toLowerCase())) {
            validationErrors['email'] = "Your email address does not look valid";
        }

        // contact should be a number of ranging from 7 - 10
        if(isNaN(parseInt(this.state.contact)) || this.state.contact.length < 7 || this.state.length > 10) {
            validationErrors['contact'] = "Contact should only be a number, it should range from 7 to 10 digits";
        }

        // descriptions should at least be 25 characters long
        if(this.state.descriptions.length < 25) {
            validationErrors['descriptions'] = "A longer description would give us more to understand your project";
        }

        // requirements should at least be 25 characters long
        if(this.state.requirements.length < 25) {
            validationErrors['requirements'] = "A longer requirement would give us more to understand your need";
        }

        // platform check
        if(this.state.platform.length === 0) {
            validationErrors['platform'] = "Let us know your platform";
        }

        // model check
        if(this.state.model.length === 0) {
            validationErrors['model'] = "Would you like to oursource your project or hire developers, please select";
        }

        return validationErrors;

    }

    _toggleActiveTab = (activeTabPosition) => {
        this.setState({
            activeTabPosition
        });
    }

    _updateFormFields = (event) => {
        
        let target = null;
        let platform = this.state.platform;
        let model = this.state.model;
        
        if(event.nodeType) {
            target = event;
        }
        else{
            target = event.target;
        }


        if(target.getAttribute('type') === 'checkbox') {
            if(target.getAttribute('name') === 'platform'){
                let value = target.getAttribute('value');
                if(target.checked) {
                    if(!platform.includes(value)) {
                        platform.push(target.getAttribute('value'));
                    }
                }
                else{
                    platform = platform.filter(item => item !== value );
                }

                this.setState({
                    platform
                });
            }
            else if(target.getAttribute('name') === 'model'){
                let value = target.getAttribute('value');
                if(target.checked) {
                    if(!model.includes(value)) {
                        model.push(target.getAttribute('value'));
                    }
                }
                else{
                    model = model.filter(item => item !== value );
                }

                this.setState({
                    model
                });
            }
        }
        else{
            this.setState({
                [target.getAttribute('name')]: target.getAttribute('type') === 'checkbox' ? target.checked : target.value
            });
        }
        
    }

    _handleOnSubmit = event => {
        event.preventDefault();

        if(!this.state.requestBeingMade) {

            let validationErrors = this._validateFields();

            this.setState({
                validationErrors
            }, () => {

                if(Object.keys(validationErrors).length === 0) {

                    this.setState({
                        requestBeingMade: true
                    })

                    let apiCall = request('mail/sendquote', 'post', this.state);  

                    apiCall.then(response => {

                        this.setState({
                            notifier: {
                                type: "success",
                                message: response.data.message
                            },
                            requestBeingMade: false
                        });

                    })
                    .catch(error => {

                        this.setState({
                            notifier: {
                                type: "error",
                                message: "We could not receive your message"
                            },
                            requestBeingMade: false
                        });

                    });
                }
                
            });

        }
        
    }

    _removeNotification = () => {
        this.setState({
            notifier: {}
        });
    }

    render() {

        return (

            <React.Fragment>

                <form onSubmit={this._handleOnSubmit} id="quoteForm">
                    
                    <div className="tabTitles">
                        <ul>
                            <li className={this.state.activeTabPosition === 1 ? 'active' : ''} data-tab-index="1" onClick={() => this._toggleActiveTab(1)} >
                                Platform
                                {
                                    this.state.validationErrors.platform || this.state.validationErrors.model ? <SVG src={WarningIcon} /> : null
                                }
                            </li>
                            <li className={this.state.activeTabPosition === 2 ? 'active' : ''} data-tab-index="2" onClick={() => this._toggleActiveTab(2)} >
                                Project
                                {
                                    this.state.validationErrors.descriptions || this.state.validationErrors.requirements || this.state.validationErrors.technologies ?
                                    <SVG src={WarningIcon} /> : null
                                }
                            </li>
                            <li className={this.state.activeTabPosition === 3 ? 'active' : ''} data-tab-index="3" onClick={() => this._toggleActiveTab(3)} >
                                Contact
                                {
                                    this.state.validationErrors.fullName || this.state.validationErrors.email || this.state.validationErrors.contact ?
                                    <SVG src={WarningIcon} /> : null
                                }
                            </li>
                        </ul>
                    </div>

                    <div className="tabContents">
                    
                        <div className={`tabContent ${this.state.activeTabPosition === 1 ? 'active' : ''}`} data-tab-index="1">

                            <div className="formCategory">
                                <label>Our Project Is</label>
                                <div className={`inputs row ${this.state.validationErrors.platform ? 'error' : ''}`}>
                                    <CheckBox label="Web" name="platform" value="web" onChange={this._updateFormFields}/>
                                    <CheckBox label="Mobile" name="platform" value="mobile" onChange={this._updateFormFields}/>
                                    <CheckBox label="Database" name="platform" value="database" onChange={this._updateFormFields}/>
                                    <CheckBox label="Server" name="platform" value="server" onChange={this._updateFormFields}/>
                                    {this.state.validationErrors.platform ? <span className="error">{this.state.validationErrors.platform}</span> : null}
                                </div>
                                
                            </div>

                            <div className="formCategory">
                                <label>We would like to</label>
                                <div className={`inputs row ${this.state.validationErrors.model ? 'error' : ''}`}>
                                    <CheckBox label="Hire Developers" value="hireDevelopers" name="model" onChange={this._updateFormFields}/>
                                    <CheckBox label="Outsource" name="model" value="outSource" onChange={this._updateFormFields}/>
                                    {this.state.validationErrors.model ? <span className="error">{this.state.validationErrors.model}</span> : null}
                                </div>
                            </div>

                            <div className="formCategory">
                                <label>Developers We'll need</label>
                                <div className="inputs row">
                                    <Input name="numberOfDevelopers" onKeyUp={this._updateFormFields} />
                                </div>
                            </div>

                            <Button type="button" onClick={() =>  this._toggleActiveTab(2)} value="Next" />

                        </div>

                        <div className={`tabContent ${this.state.activeTabPosition === 2 ? 'active' : ''}`} data-tab-index="2">
                            <div className="formCategory">
                                <label>Project Description</label>
                                <div className="inputs">
                                    <TextArea name="descriptions" className={this.state.validationErrors.descriptions ? 'error' : ''} placeholder="explain your project in brief" onKeyUp={this._updateFormFields}/>
                                    {this.state.validationErrors.descriptions ? <span className="error">{this.state.validationErrors.descriptions}</span> : null}
                                </div>
                            </div>
                            <div className="formCategory">
                                <label>Project Requirements</label>
                                <div className="inputs">
                                    <TextArea name="requirements" className={this.state.validationErrors.requirements ? 'error' : ''} placeholder="mention your functional requirement" onKeyUp={this._updateFormFields}/>
                                    {this.state.validationErrors.requirements ? <span className="error">{this.state.validationErrors.requirements}</span> : null}
                                </div>
                            </div>
                            <div className="formCategory">
                                <label>Technologies I want to use</label>
                                <div className="inputs">
                                    <Input name="technologies" className={this.state.validationErrors.technologies ? 'error' : ''} onKeyUp={this._updateFormFields}/>
                                    <div className="technologiesSelected"></div>
                                    {this.state.validationErrors.technologies ? <span className="error">{this.state.validationErrors.technologies}</span> : null}
                                </div>
                            </div>

                            <Button type="button" onClick={() =>  this._toggleActiveTab(3)} value="Next" />
                        </div>

                        <div className={`tabContent ${this.state.activeTabPosition === 3 ? 'active' : ''}`} data-tab-index="3">

                            <div className="formCategory">
                                <label>Full Name</label>
                                <div className="inputs">
                                    <Input name="fullName" className={this.state.validationErrors.fullName ? 'error' : ''} onKeyUp={this._updateFormFields}/>
                                    {this.state.validationErrors.fullName ? <span className="error">{this.state.validationErrors.fullName}</span> : null}
                                </div>
                            </div>

                            <div className="formCategory">
                                <label>Email Address</label>
                                <div className="inputs">
                                    <Input name="email" className={this.state.validationErrors.email ? 'error' : ''} onKeyUp={this._updateFormFields}/>
                                    {this.state.validationErrors.email ? <span className="error">{this.state.validationErrors.email}</span> : null}
                                </div>
                            </div>

                            <div className="formCategory">
                                <label>Phone Number</label>
                                <div className="inputs">
                                    <Input name="contact" className={this.state.validationErrors.contact ? 'error' : ''} onKeyUp={this._updateFormFields}/>
                                    {this.state.validationErrors.contact ? <span className="error">{this.state.validationErrors.contact}</span> : null}
                                </div>
                            </div>

                            <div className="formCategory">
                                <label>Address</label>
                                <div className="inputs">
                                    <Input name="address" onKeyUp={this._updateFormFields}/>
                                </div>
                            </div>

                            <Button 
                                onClick={this._submitForm} 
                                value={
                                    this.state.requestBeingMade ? <LoaderBars />
                                    : "Get Quote"
                                } 
                            /> 

                        </div>
                    
                    </div>

                </form>

                { Object.keys(this.state.notifier).length !== 0 ? <Notifier removeNotification={this._removeNotification} type={this.state.notifier.type} notification={this.state.notifier.message} /> : null }

            </React.Fragment>

        );

    }

}

export class QuoteFormInsideModal extends React.Component {

    render() {

        return (
    
            <ModalContext.Consumer>
                {
                    ({getQuoteModalVisibility, closeGetQuoteModal}) => (
                        <Modal 
                            title={<h3>AWESOME !! Let's Do This</h3>}
                            body={<QuoteForm />}
                            modalVisibility={getQuoteModalVisibility}
                            closeGetQuoteModal={closeGetQuoteModal}
                        />
                    )
                }
            </ModalContext.Consumer>

        );

    }

}