/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-26 15:58:02
 * @modify date 2018-11-26 15:58:02
 * @desc [description]
*/

import React from 'react';

import request from '../../../api/request';

import { Input, TextArea } from '../../../components/form/forminputs';
import Button from '../../../components/button/button';
import Notifier from './../../../components/notifier/notifier';

import { LoaderBars } from './../../../components/loader/loader';

import SVG from 'react-inlinesvg';

import SendIcon from '../assets/svg/sent mail.svg'

import './contactform.scss';

export default class ContactForm extends React.Component {

    constructor() {

        super();

        this.state = {
            fullName:'',
            email:'',
            contact:'',
            message:'',
            validationErrors: {},
            notifier: {},
            requestBeingMade: false
        }

    }

    _validateFields = () => {

        let validationErrors = {};

        let emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        // fullname at least 2 characters
        if(this.state.fullName.length < 2) {
            validationErrors['fullName'] = "That's a very short name, please check again.";
        }

        // email must match patter
        if(!emailPattern.test(String(this.state.email).toLowerCase())) {
            validationErrors['email'] = "Your email address does not look valid";
        }

        // contact should be a number of ranging from 7 - 10
        if(isNaN(parseInt(this.state.contact)) || this.state.contact.length < 7 || this.state.length > 10) {
            validationErrors['contact'] = "Contact should only be a number, it should range from 7 to 10 digits";
        }

        // message should at least be 10 characters long
        if(this.state.message.length < 10) {
            validationErrors['message'] = "We enjoy reading your messages, a bit longer please";
        }

        return validationErrors;

    }

    _updateValue = (event) => {

        let field = event.target;
        
        this.setState({
            [field.getAttribute('name')]: field.value 
        });

    }

    _submitForm = (event) => {

        event.preventDefault();

        if(!this.state.requestBeingMade) {

            let validationErrors = this._validateFields();

            this.setState({
                validationErrors
            }, () => {

                if(Object.keys(validationErrors).length === 0) {
                    let data = {
                        fullName: this.state.fullName,
                        email: this.state.email,
                        contact: this.state.contact,
                        message: this.state.message
                    }

                    this.setState({
                        requestBeingMade: true
                    });
        
                    let apiCall = request('/mail/sendmessage', 'post', data);

                    apiCall.then(response => {
                        this.setState({
                            notifier: {
                                type: "success",
                                message: response.data.message
                            },
                            requestBeingMade: false
                        });

                    })
                    .catch(error => {
                        this.setState({
                            notifier: {
                                type: "error",
                                message: "We could not receive your message"
                            },
                            requestBeingMade: false
                        });

                    });
                }

            });

        }

    }

    _removeNotification = () => {
        this.setState({
            notifier: {}
        });
    }

    render() {

        return(

            <React.Fragment>

                <form method="post">
                
                    <Input name="fullName" placeholder="Your Name" className={this.state.validationErrors.fullName ? 'error' : ''} onKeyUp={this._updateValue} />
                    {this.state.validationErrors.fullName ? <span className="error">{this.state.validationErrors.fullName}</span> : null}
                    <Input name="email" placeholder="Your Email" className={this.state.validationErrors.email ? 'error' : ''} onKeyUp={this._updateValue} />
                    {this.state.validationErrors.email ? <span className="error">{this.state.validationErrors.email}</span> : null}
                    <Input name="contact" placeholder="Your Phone Number" className={this.state.validationErrors.contact ? 'error' : ''} onKeyUp={this._updateValue} />
                    {this.state.validationErrors.contact ? <span className="error">{this.state.validationErrors.contact}</span> : null}
                    <TextArea name="message" placeholder="Your Message" className={this.state.validationErrors.message ? 'error' : ''} onKeyUp={this._updateValue} />
                    {this.state.validationErrors.message ? <span className="error">{this.state.validationErrors.message}</span> : null}
                    <Button 
                        onClick={this._submitForm} 
                        value={
                            this.state.requestBeingMade ? <LoaderBars />
                            : <React.Fragment><SVG src={SendIcon} /> <span>Send Message</span></React.Fragment>
                        } 
                    />
                
                </form>

                { Object.keys(this.state.notifier).length !== 0 ? <Notifier removeNotification={this._removeNotification} type={this.state.notifier.type} notification={this.state.notifier.message} /> : null }

            </React.Fragment>

        );

    }

}