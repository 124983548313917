/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-19 13:53:21
 * @modify date 2018-11-19 13:53:21
 * @desc [description]
*/

import React from 'react';

import { Link } from 'react-router-dom';

const quickLinks = () => (

    <div>
        <h4>Quick Links</h4>
        <ul>
            <li>
                <Link to="/work">Work</Link>
            </li>
            <li>
                <Link to="/about">About</Link>
            </li>
            <li>
                <Link to="/careers">Careers</Link>
            </li>
            <li>
                <Link to="/contact">Contact</Link>
            </li>
        </ul>
    </div>

);

export default quickLinks;