/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-22 17:03:31
 * @modify date 2018-11-22 17:03:31
 * @desc [description]
*/

import React from 'react';

import Slider from 'react-slick';

import IVFScreenshot from './assets/images/IVF_LM.png';
import HCOELogo from './assets/images/hcoe.png';
import KMCLogo from './assets/images/kmclogo.png';
import NeutrosysLogo from './assets/images/neutrosys.png';
import SkylineLogo from './assets/images/skyline logo.png';
import TomiaLogo from './assets/images/tomia.png';
import Kavadev from './assets/images/kavadev.png';
import quote from '../../../assets/svg/quote.svg';
import Placeholder1 from './assets/images/placeholder_1.png';
import Placeholder2 from './assets/images/placeholder_2.png';
import Placeholder3 from './assets/images/placeholder_3.png';

import './clients.scss';

const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    autoplaySpeed: 10000,
    autoplay: true,
    arrows: false
}

const clients = [

    {
        name:"",
        photo:Placeholder2,
        company: "Neutro IT Services",
        image: NeutrosysLogo,
        link: 'http://neutrosys.com',
        testimonial: "We are very thankful to have formed excellent partnerships with KimbuTech, a Nepal based IT company. KimbuTech is everything you want in a business partner. They take the time to understand what your business needs are, and the costumer services and the end results are always top notch. We appreciate their attention to details and creative approaches. We developed a great partnership and we are very excited to work with this company. We’ve formed more than just working relationships with this company - we have formed true friendships."
    },
    {
        name:"Dr. Swasti Sharma",
        photo:Placeholder1,
        company: "IVF Nepal Pvt. Ltd.",
        image: IVFScreenshot,
        link:"https://ivfnepal.com",
        testimonial:"I am very happy by the dynamic website Kimbu Tech developed for my company. It's truly interactive and user friendly. I must say they are quick and efficient at what they do. They are mostly available when it comes to interacting with the clients. I highly recommend their service."
    },
    {
        name:"",
        photo:Placeholder2,
        company: "Himalaya College of Engineering",
        image: HCOELogo,
        testimonial:"We are very thankful for their guidance to adopting the steps for smart college as it has improved effectiveness of our institution. They are fun to work with. It's worth every penny we spend . We look forward to building more trust along with growth."
    },
    {
        name:"",
        photo:Placeholder3,
        company: "KMC Network",
        image: KMCLogo,
        testimonial:"We are excited to work with Kimbu tech . The management has been instantly responsive and taking personal interest into our subject matter. We look forward to successful partnership as we work together in the future."
    },
    {
        name:"",
        photo:Placeholder1,
        company: "Skyline Architects Pvt. Ltd.",
        image: SkylineLogo,
        testimonial:"We are very much impressed by the work accomplished by Kimbu Tech with in the limited time. There work has helped us in growth of our company and we promise to work together for future projects too."
    },
    // {
    //     name:"",
    //     photo:Placeholder3,
    //     company: "kavadev",
    //     image: Kavadev,
    //     link: 'https://kavadev.com/',
    //     testimonial: ""
    // },
    // {
    //     name:"",
    //     photo:Placeholder3,
    //     company: "kavadev",
    //     image: TomiaLogo,
    //     link: 'https://kavadev.com/',
    //     testimonial: ""
    // },
    
];

export const ClientSection = () => (

    <React.Fragment>

        <div className="roundedBackground"></div>
        
        <div className="sliderContainer">
            <Slider {...sliderSettings} >

                {
                    clients && clients.map((client, index) => (
                        <div className="sliderItem" key={index}>
                            <Clients 
                                image={client.image} 
                                client={client}
                            />
                        </div>
                    ))
                }

            </Slider>
        </div>
        

    </React.Fragment>

);

const Clients = (props) => (

    <div className="client">

        <div className="productWrapper">
        
            <a href={props.client.link} target="_blank" rel="noopener noreferrer">
                <img src={props.image} alt=""/>
            </a>    

        </div>

        <div className="testimonialWrapper">
        
            <div className="imageWrapper">
                <img src={props.client.photo} alt=""/>
            </div>

            <div className="clientInfo">
                <div>{props.client.name}</div>
                <div>{props.client.company}</div>
            </div>

            <div className="testimonial">

                <img src={quote} alt=""/>
                {props.client.testimonial}

            </div>

        </div>

    </div>

);