/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-19 13:24:30
 * @modify date 2018-11-19 13:24:30
 * @desc [description]
*/

import React from 'react';
import { Link } from 'react-router-dom';

import Contact from './contact/contact';
import QuickLinks from './quicklinks/quicklinks';
import Services from './services/services';
import Social from './social/social';
import Message from './message/message';

import Logo from '../../assets/svg/logo.svg';

import './footer.scss';

const footer = () => (

    <React.Fragment>
        <div>
            <Message />
        </div>

        <footer>

            <div className="title">

                <div className="logoContainer">
                    <Link to="/"><img src={Logo} alt="Kimbu Tech" /></Link>
                </div>

                <h1>Making Technology Accessible</h1>

            </div>

            <div className="contents">

                <Contact />
                <QuickLinks />
                <Services /> 
                <Social />

            </div>

            <div className="copyright">
                &copy; All Rights Reserved. Kimbu Tech Pvt. Ltd.
            </div>

            <div className="gradient"></div>

        </footer>

    </React.Fragment>

);

export default footer;