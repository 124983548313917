/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-09 13:07:02
 * @modify date 2018-12-21 15:14:42
 * @desc [description]
*/

import React from 'react';

import Button from '../../../components/button/button';
import Notifier from '../../../components/notifier/notifier';
import request from '../../../api/request';
import { LoaderBars } from './../../../components/loader/loader';

export default class SharePosition extends React.Component {

    constructor() {
        super();
        this.state = {
            emailAddress: '',
            notifier: {},
            requestBeingMade: false,
            copied: false
        }
    }

    componentDidMount = () => {
         this._facebookshare();
    }

    _facebookshare =() => {
         // loading js for facebookshare
         (function(d, s, id) {
            let js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2&appId=1080703522103972&autoLogAppEvents=1';
            fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));

    }

    _updateValue = (event) => {

        this.setState({
            emailAddress: event.target.value
        });

    }

    _copy = () => {
        this.refs.copyInput.select();
        document.execCommand('copy');
        this.setState({
            copied: true
        })
    }

    _removeNotification = () => {
        this.setState({
            notifier: {}
        });
    }

    _sendMail = () => {

        if(!this.state.requestBeingMade) {

            this.setState({
                requestBeingMade: true
            });

            let apiCall = request('/mail/suggestfriend', 'post', {
                email: this.state.emailAddress,
                data: `https://kimbutech.com/careers/${this.props.activePosition.replace(/\s/g, '_')}`
            });
    
            apiCall.then(response => {
                this.setState({
                    notifier: {
                        type: "success",
                        message: response.data.message
                    },
                    requestBeingMade: false
                });
    
            })
            .catch(error => {
                this.setState({
                    notifier: {
                        type: "error",
                        message: "We couldn't mail the link"
                    },
                    requestBeingMade: false
                });
    
            });
        }

    }

    render() {

        return (

            <React.Fragment>

                <div className="share">
                    <div className="formRow">
                        <input 
                            ref="copyInput"
                            type="text" 
                            value={`https://kimbutech.com/careers/${this.props.activePosition !== null ? this.props.activePosition.replace(/\s/g, '_') : ''}`} 
                            readOnly={true}
                        />
                        <button onClick={this._copy} >{this.state.copied ? "Copied" : "Copy"}</button>
                    </div>
                    <div className="formRow">
                        <input type="text" placeholder="Email Address" onKeyUp={this._updateValue} />
                        <Button 
                            onClick={this._sendMail} 
                            value={
                                this.state.requestBeingMade ? <LoaderBars />
                                : "Mail Link"
                            } 
                        />
                    </div>
                    <div className="formRow">
                        {/* eslint-disable-next-line */}
                        <div 
                            className="fb-share-button"
                            data-href=
                                {
                                    `https://kimbutech.com/careers/${this.props.activePosition !== null ? this.props.activePosition.replace(/\s/g, '_') : ''}`
                                } 
                            data-layout="button" 
                            data-size="large" 
                            data-mobile-iframe="true"
                        >

                            <a 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                href="https://www.facebook.com/sharer/sharer.php?u=https://kimbutech.com%2F&amp;src=sdkpreparse" 
                                className="fb-xfbml-parse-ignore"
                            >
                                Share
                            </a>

                        </div>
                    </div>
                </div>

                { Object.keys(this.state.notifier).length !== 0 ? <Notifier removeNotification={this._removeNotification} type={this.state.notifier.type} notification={this.state.notifier.message} /> : null }


            </React.Fragment>
            
        );


    }

}