/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-05-14 15:39:01
 * @modify date 2019-05-14 15:39:01
 * @desc [description]
*/

import React from 'react';

import Notifier from './../../../components/notifier/notifier';

import Section from '../../../components/section/section';
import { LoaderBars } from './../../../components/loader/loader';
import LOADER from '../../../components/loader/loader.gif';
import Select from 'react-select';

import './courseindividual.scss';

const colourStyles = {
    control: styles => ({
        ...styles, 
        margin: '5px 0 0',
        backgroundColor: 'white', 
        border: '1px solid rgba(0,0,0,0.10)', 
        boxShadow: 'none',
        ':hover': {
            border: '1px solid #34BFF3',
            boxShadow: 'none',
            borderColor: 'rgba(0,0,0,0.10)'
        },
    }),
    // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //     return {
    //       ...styles,
    //       backgroundColor: isDisabled
    //         ? null
    //         : isSelected ? data.color : isFocused ? color.alpha(0.1).css() : null,
    //       color: isDisabled
    //         ? '#ccc'
    //         : isSelected
    //           ? chroma.contrast(color, 'white') > 2 ? 'white' : 'black'
    //           : data.color,
    //       cursor: isDisabled ? 'not-allowed' : 'default',
    //       fontSize: '14px',
    
    //       ':active': {
    //         ...styles[':active'],
    //         backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
    //       },
    //     };
    //   },
};

const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


const convertTo12HRFormat = date => {
	let hours = date.getHours();
	let minutes = date.getMinutes();
	let ampm = hours >= 12 ? 'PM' : 'AM';
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? '0'+minutes : minutes;
	let strTime = hours + ':' + minutes + ' ' + ampm;
	return strTime;
}

export default class CourseIndividual extends React.Component {

    constructor() {

        super();

        this.state = {
            validationErrors:{}
        }

    }

    _validateFields = () => {

        let {fullName, email, contactNumber, classId} = this.props.registrationDetails;

        let validationErrors = {};

        let emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        // fullname at least 2 characters
        if(fullName.trim().length < 2) {
            validationErrors['fullName'] = "That's a very short name, please check again.";
        }

        // email must match patter
        if(!emailPattern.test(String(email).toLowerCase())) {
            validationErrors['email'] = "Your email address does not look valid";
        }

        // contact should be a number of ranging from 7 - 10
        if(isNaN(parseInt(contactNumber)) || contactNumber.length < 7 || contactNumber.length > 10) {
            validationErrors['contactNumber'] = "Contact should only be a number, it should range from 7 to 10 digits";
        }

        // classId must be selected
        if(classId.value === '' || classId === '') {
            validationErrors['classId'] = "Please choose a class date.";
        }

        return validationErrors;

    }

    _register = () => {

        let validationErrors = this._validateFields();

        if(Object.keys(validationErrors).length === 0) {
            this.props._register();
        }
        this.setState({
            validationErrors
        });
    }

    render() {

        if(this.props.loading) {
            return <Section title={this.props.course.title} body={<img src={LOADER} alt="Loading ..." />}></Section>
        }

        return (

            <React.Fragment>

                <Section 
                    title={this.props.course.title} 
                    body={
                        <Course 
                            course={this.props.course}
                            registering={this.props.registering}
                            registrationDetails={this.props.registrationDetails} 
                            _updateFormFields={this.props._updateFormFields} 
                            _register={this._register}
                            validationErrors={this.state.validationErrors}
                        />
                    } 
                />

                {
                    Object.keys(this.props.notifier).length !== 0 ? 
                        <Notifier removeNotification={this.props._clearNotification} type={this.props.notifier.type} notification={this.props.notifier.message} />
                        :
                        null
                }

            </React.Fragment>

        );

    }

}

const Course = (props) => (
    <>

        <p>
            {props.course.description}
        </p>

        <CourseDetails 
            course={props.course}
            registrationDetails={props.registrationDetails} 
            _updateFormFields={props._updateFormFields} 
            registering={props.registering} 
            _register={props._register}
            validationErrors={props.validationErrors}
            classes={props.course.classes}
        />

    </>
)

const CourseDetails = (props) => (

    <div className="registration">   

        <h5><u>{props.course.title.charAt(0).toUpperCase() + props.course.title.slice(1).toLowerCase()} is being held in the following dates, find the right one for you and register</u></h5>
        <div className="dates">
            {
                props.classes.length > 0 ?
                    // @param 'c' = 'class'
                    props.classes.map((c,i) => (<DateCard class={c} key={i} />))
                    :
                    <div className="message failure">Sorry, there are no classes right now. Please visit our website to be updated on upcoming classes.</div>
            }
        </div>

        {
            props.classes.length > 0 ?
                <Registration 
                    _updateFormFields={props._updateFormFields} 
                    registrationDetails={props.registrationDetails} 
                    registering={props.registering} 
                    _register={props._register}
                    validationErrors={props.validationErrors}
                    classes={props.classes.map(c => ({
                        numberOfAvailableSeat: c.numberOfAvailableSeat,
                        date: c.startTime.split("T")[0],
                        id: c.id,
                        isDisabled: c.numberOfAvailableSeat < 1
                    }))}
                    // classes={props.classes.map(c => {

                    //     if(c.numberOfAvailableSeat > 0) {
                    //         return {
                    //             numberOfAvailableSeat: c.numberOfAvailableSeat,
                    //             date: c.startDate,
                    //             id: c.id
                    //         }
                    //     }
                    // })}
                />
                :
                null
        }

    </div>

)

const DateCard = (props) => {

    let endDate = new Date(props.class.endTime);
    let startDate = new Date(props.class.startTime);
    let date = startDate.getDate();

    let startTime = convertTo12HRFormat(startDate);
    let endTime = convertTo12HRFormat(endDate);

    startDate = `${MONTHS[startDate.getMonth()]} ${date.toString().length === 1 ? `0${date}` : date}`;

    return (
        <div className={`date-block ${props.class.numberOfAvailableSeat > 0 ? 'active': 'dormant'}`}>
            <h4>{`${startDate}, ${props.class.duration}`}</h4>
            <div className={`days ${props.class.numberOfAvailableSeat !== 0 ? 'active' : ''}`}>
                {
                    props.class.days.map((day,i) => `${day}${i !== props.class.days.length-1 ? ',' : ''}`)
                }
            </div>
            <span>From: {startTime}, To: {endTime}</span>
            <span>{props.class.numberOfAvailableSeat > 0 ? 'Registration Open.' : 'Registration Closed.'}</span>
        </div>
    )
}

const Registration = (props) => { 

    let classOptions = props.classes.map(c => ({
        value: c.id.toString(),
        label: `${c.date} (${c.numberOfAvailableSeat} seats available)`,
        isDisabled: c.isDisabled
    }))

    return (
        <form action="">
        <h4>Fill the form below to regsiter</h4>
        <div className="input-group">
            <label htmlFor="fullName">Full Name (Required)</label>
            <input value={props.registrationDetails.fullName} onChange={e => props._updateFormFields('fullName', e.target.value)} id="fullName" type="text" name="fullName" placeholder="" />
            <span className='error'>{props.validationErrors.fullName}</span>
        </div>
        <div className="input-group">
            <label htmlFor="email">Email (Required)</label>
            <input value={props.registrationDetails.email} onChange={e => props._updateFormFields('email', e.target.value)} id="email" type="text" name="email" placeholder="" />
            <span className='error'>{props.validationErrors.email}</span>
        </div>
        <div className="input-group">
            <label htmlFor="contactNumber">Contact Number (Required)</label>
            <input value={props.registrationDetails.contactNumber} onChange={e => props._updateFormFields('contactNumber', e.target.value)} id="contactNumber" type="text" name="contactNumber" placeholder="" />
            <span className='error'>{props.validationErrors.contactNumber}</span>
        </div>
        <div className="input-group">
            <label htmlFor="subject">Class Date (Required)</label>
            <Select value={props.registrationDetails.classId} options={classOptions} styles={colourStyles} onChange={(value, actionMeta) => props._updateFormFields('classId', value) } />
            <span className='error'>{props.validationErrors.classId}</span>
        </div>
        <div className="input-group">
            <label htmlFor="college">College</label>
            <input value={props.registrationDetails.college} onChange={e => props._updateFormFields('college', e.target.value)} id="college" type="text" name="college" placeholder="" />
        </div>

        <button type="button" onClick={props._register}>
            {
                props.registering ? <LoaderBars /> : "Register"
            }
        </button>
    </form>
    )
}

