/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-18 16:41:20
 * @modify date 2018-11-18 16:41:20
 * @desc [description]
*/

let posX1, posX2, startTime, endTime, minDistance=100, minDuration=100, swipeLeft, swipeRight;

if(typeof Event === 'function') {
    swipeLeft = new Event('swipeLeft');
    swipeRight = new Event('swipeRight');
}
export const detectSwipe = () => {

    if(typeof Event === 'function') {

        window.addEventListener('touchstart', (event) => {

            let touchObject = event.changedTouches[0];
    
            // initial touch point
            posX1 = touchObject.pageX;
            startTime = new Date().getTime();
    
        });
    
        window.addEventListener('touchend', (event) => {
    
            let touchObject = event.changedTouches[0];
    
            // final touch point
            posX2 = touchObject.pageX;
            endTime = new Date().getTime();
    
            if(endTime - startTime >= minDuration) {
    
                if(posX1 - posX2 >= minDistance) {
                    window.dispatchEvent(swipeLeft);
                }
                else if(posX2 - posX1 >= minDistance) {
                    window.dispatchEvent(swipeRight);
                }
    
            }
    
        });

    }

}