/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-19 13:30:32
 * @modify date 2018-11-19 13:30:32
 * @desc [description]
*/

import React from 'react';

import CellPhoneIcon from '../../../assets/svg/cell phone.svg';
import CallAnswerIcon from '../../../assets/svg/call-answer.svg';
import MarkerIcon from '../../../assets/svg/marker.svg';
import EnvelopeIcon from '../../../assets/svg/envelope.svg';

const Contact = () => (

    <div className="footerBlock">
        <h4>Contact</h4>
        <ul>
            <li>
                <img src={MarkerIcon} alt="Address"/>
                Maitighar, Kathmandu, Nepal
            </li>
            <li>
                <img src={EnvelopeIcon} alt="Mail"/>
                <a href="mailto:info@kimbutech.com">info@kimbutech.com</a>
            </li>
            <li>
                <img src={CallAnswerIcon} alt="Phone"/>
                <a href="tel:+977 01 4215092">( 977 ) - 01 - 4215092</a>
            </li>
            <li>
                <img src={CellPhoneIcon} alt="Mobile"/>
                <a href="tel:+977 9851012578">( 977 ) - 98510 - 99866</a>
            </li>
        </ul>
    </div>

);

export default Contact;