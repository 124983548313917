/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-12 16:15:00
 * @modify date 2018-12-12 16:15:00
 * @desc [description]
*/

import React from 'react';
import { PropTypes } from 'prop-types';

import './notifier.scss';

export default class Notifier extends React.Component {

    constructor() {

        super();

        this.state = {
            visibility: true
        }

    }

    _hideNotification = () => {
        this.setState({
            visibility: false
        });
    }

    componentDidUpdate(prevState) {
        if(this.state.visibility === prevState.visibility){
            // console.log("yippeeee");
        }
    }

    render() {

        return (

            <div className={`notifier ${this.props.type} ${ this.state.visibility ? 'visible' : ''} ${ this.props.visibility ? 'visible' : ''}`}>
                <span>{this.props.notification}</span>
                <span onClick={() => {
                    this._hideNotification();
                    setTimeout(() => this.props.removeNotification(), 300);
                }}
                    >&times;
                </span>
            </div>

        );

    }

}

Notifier.propTypes = {
    type: PropTypes.string,
    notification: PropTypes.string.isRequired
}

Notifier.defaultProps = {
    type: "success"
}