/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-05-15 13:06:48
 * @modify date 2019-05-15 13:06:48
 * @desc [description]
*/

import React from 'react';
import CourseIndividual from './courseindividual';
import request from '../../../api/request';

export default class CourseIndividualContainer extends React.Component {

    constructor() {

        super(); 

        this.state = {
            registrationDetails: {
                fullName: '',
                email: '',
                contactNumber: '',
                classId: '',
                college: ''
            },
            registering: false,
            loading: true,
            course: {
                title: '',
                description: '',
                id: 1,
                status: 1,
                classes: [
                    {
                        days:[],
                        duration: '',
                        id: '',
                        numberOfAvailableSeat: '20',
                        numberOfSeat: '20',
                        startDate: '',
                        startTime: '',
                    }
                ]
            },
            notifier: {}
        }

    }

    componentDidMount() {

        request(`data/classes/bycourse/${this.props.course.replace("_", " ")}`)
        .then(res => {
            
            let course = res.data.course;
            course.classes = res.data.classes;

            this.setState({
                course,
                loading: false,
            });


        })
        .catch(err => {
            console.log(err);
            this.setState({
                loading: false,
                notifier: {
                    type:'error',
                    message: err.response ? err.response.message : 'Something went wrong, please try again.'
                }
            })
        });

    }

    _updateFormFields = (field, value) => {

        let { registrationDetails } = this.state;

        registrationDetails[field] = value;

        this.setState({
            registrationDetails
        });

    }

    _register = () => {

        this.setState({
            registering: true
        });

        let registrationDetails = {...this.state.registrationDetails};
        registrationDetails.classId = registrationDetails.classId.value;

        request('data/registration', 'post', registrationDetails)
        .then(res => {

            let course = {
                ...this.state.course
            }

            course.classes.some(c => {
                if(c.id.toString() === registrationDetails.classId) {
                    c.numberOfAvailableSeat = res.data.numberOfAvailableSeat;
                    return true;
                }
                else{
                    return false;
                }
            });

            this.setState({
                registering: false,
                notifier: {
                    type:'success',
                    message: res.data.message
                },
                registrationDetails:{
                    fullName: '',
                    email: '',
                    contactNumber: '',
                    classId: '',
                    college: ''
                }
            });
        })
        .catch(err => {
            console.log(err);
            this.setState({
                registering: false,
                notifier: {
                    type:'error',
                    message: err.response && err.response.data ? err.response.data.message : 'Something went wrong, please try again.'
                }
            });
        });

    }

    _clearNotification = () => {
        this.setState({
            notifier: {}
        });
    }

    render() {

        return <CourseIndividual 
                    course={this.state.course} 
                    registering={this.state.registering}
                    registrationDetails={this.state.registrationDetails} 

                    loading={this.state.loading}
                    notifier={this.state.notifier}

                    _updateFormFields={this._updateFormFields} 
                    _register={this._register}
                    _clearNotification={this._clearNotification}
                />

    }

}