/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-19 13:58:23
 * @modify date 2018-11-19 13:58:23
 * @desc [description]
*/

import React from 'react';
import {
    AndroidView,
    IOSView,
    BrowserView,
} from "react-device-detect";

import FBIcon from '../../../assets/svg/fb.svg';
import TwitterIcon from '../../../assets/svg/twitter.svg';
import GithubIcon from '../../../assets/svg/github.svg';
import WhatsappIcon from '../../../assets/svg/whatsapp.svg';
import ViberIcon from '../../../assets/svg/viber.svg';

import './social.scss';

const redirectToApp = () => {
    var now = new Date().valueOf();
    setTimeout(function () {
        if (new Date().valueOf() - now > 100) return;
        window.open("https://facebook.com/KimbuTech", "_system");
    }, 25);
    window.open("fb://profile/774645196259323", "_system");
}
const redirectToPlay = () => {
    var now = new Date().valueOf();
    setTimeout(function () {
        if (new Date().valueOf() - now > 100) return;
        window.open("https://facebook.com/KimbuTech");
    }, 25);
    window.location = "fb://page/774645196259323";
}

const socialLinks = () => (

    <div className="footerBlock">
        <h4>Social</h4>
        <ul>
            <li>
                <BrowserView> 
                    <a target='_blank' rel="noopener noreferrer" href="https://facebook.com/KimbuTech">
                        <img src={FBIcon} alt="facebook"/>
                        <span>kimbutech</span>
                    </a>
                </BrowserView>
                <AndroidView>
                    <a target='_blank' rel="noopener noreferrer" onClick={redirectToPlay}>
                        <img src={FBIcon} alt="facebook"/>
                        <span>kimbutech</span>
                    </a>
                </AndroidView>
                <IOSView>
                    <a target='_blank' rel="noopener noreferrer" onClick={redirectToApp}>
                        <img src={FBIcon} alt="facebook"/>
                        <span>kimbutech</span>
                    </a>
                </IOSView>
            </li>
            {/* <li>
                <a href="https://facebook.com/KimbuTech">
                    <img src={FBIcon} alt="facebook"/>
                    <span>kimbutech</span>
                </a>
            </li> */}
            <li>
                <a target='_blank' rel="noopener noreferrer" href="https://twitter.com/kimbutech">
                    <img src={TwitterIcon} alt="twitter"/>
                    <span>kimbutech</span>
                </a>
            </li>
            <li>
                <a target='_blank' rel="noopener noreferrer" href="https://github.com/kimbutech">
                    <img src={GithubIcon} alt="github"/>
                    <span>kimbutech</span>
                </a>
            </li>
            <li>
                {/* eslint-disable-next-line */}
                <a target='_blank' rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=9779865481214">
                    <img src={WhatsappIcon} alt="whatsapp"/>
                    <span>9865481214</span>
                </a>
            </li>
                        
            <li>
                {/* eslint-disable-next-line */}
                <BrowserView>
                    <a target='_blank' rel="noopener noreferrer" href="viber://chat?number=+9779865481214">
                    {/* <a href="viber://msng.link/vi/9779851099866"> */}
                        <img src={ViberIcon} alt="viber"/>
                        <span>9865481214</span>
                    </a>
                </BrowserView>
                <AndroidView>
                    <a target='_blank' rel="noopener noreferrer" href="viber://chat?number=9779865481214">
                    {/* <a href="viber://msng.link/vi/9779851099866"> */}
                        <img src={ViberIcon} alt="viber"/>
                        <span>9865481214</span>
                    </a>
                </AndroidView>
                <IOSView>
                    <a target='_blank' rel="noopener noreferrer" href="viber://chat?number=9865481214">
                        <img src={ViberIcon} alt="viber"/>
                        <span>9865481214</span>
                    </a>
                </IOSView>
            </li>

        </ul>
    </div>

);

export default socialLinks;