/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-04 12:04:36
 * @modify date 2018-12-04 12:04:36
 * @desc [description]
*/

import React from 'react';

import './modal.scss';

export default class Modal extends  React.Component {

    constructor() {

        super();

        this.state = {
            modalVisibility: false,
        }

    }

    _closeModal = (event) => {
        this.setState({
            modalVisibility: false
        });
    }

    componentWillReceiveProps = (props) => {
        this.setState({
            modalVisibility: props.modalVisibility
        });

        if(props.closeGetQuoteModal){
            this._closeModal = props.closeGetQuoteModal;
        }

        if(props.closeModal) {
            this._closeModal = props.closeModal
        }
    }

    render() {

        return (

            <div onClick={(event) => {
                if(event.target === this.refs.modalWrapper) {
                    this._closeModal();
                }
            }} ref="modalWrapper" className={`modalWrapper ${this.state.modalVisibility ? 'visible' : ''}`}>

                <div className="modal">

                    <div className="closeIcon" onClick={this._closeModal}>&times;</div>
                    {this.props.title}
                    <div className="modalBody">
                        {this.props.body}
                    </div>

                </div>

            </div>

        );

    }

}