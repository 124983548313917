// loader by DOM @ahdigital
// https://codepen.io/ahdigital/
// https://codepen.io/ahdigital/pen/prXBzN

import React from 'react';

import './loader.scss';

export const LoaderBars = () => (

    <div className="preloader-2">
        {/* <div className="text">Sending</div> */}
        <span className="line line-1"></span>
        <span className="line line-2"></span>
        <span className="line line-3"></span>
        <span className="line line-4"></span>
        <span className="line line-5"></span>
        <span className="line line-6"></span>
        <span className="line line-7"></span>
        <span className="line line-8"></span>
        <span className="line line-9"></span>
    </div>

);
