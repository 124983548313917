/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-03 12:09:51
 * @modify date 2018-12-03 12:09:51
 * @desc [description]
*/

// for technologies we work with

import React from 'react';

import ServerIcon from '../../../assets/svg/server.svg';
import DatabaseIcon from '../../../assets/svg/database.svg';
import WebDesignIcon from '../../../assets/svg/web design.svg';
import WebDevelopmentIcon from '../../../assets/svg/web development.svg';
import MobileDevelopmentIcon from '../../../assets/svg/mobile development.svg';

import HTML5Logo from './assets/svg/html 5.svg';
import CSS3Logo from './assets/svg/css3.svg';
import JavaScriptLogo from './assets/svg/js.svg'
import ReactLogo from './assets/svg/react.svg';
import AngularLogo from './assets/svg/AngularJS-large.svg';
import JqueryLogo from './assets/svg/jquery.svg';
import VueLogo from './assets/svg/vue js.svg';
import SassLogo from './assets/svg/sass.svg';
import LessLogo from './assets/svg/less_logo.svg';

import Nodejs from './assets/svg/nodejs.svg';
import Php from './assets/svg/php.png';
import Python from './assets/svg/python.svg';
import Ruby from './assets/svg/ruby.png';
import RubyonRails from './assets/svg/Ruby-on-rails.png';
import Java from './assets/svg/java.png';
import Cpp from './assets/svg/ISO_C++_Logo.svg';
import Csharp from './assets/svg/csharp.png';

import Reactnative from './assets/svg/react-native.png';
import NativeScript from './assets/svg/native-script.png';
import Andriod from './assets/svg/android.svg';
import Swift from './assets/svg/swift.png';

import PostgreSQL from './assets/svg/Postgresql.svg';
import Mysql from './assets/svg/mysql.svg';
import MongoDB from './assets/svg/MongoDB-Logo.svg';
import Neo4j from './assets/svg/neo4j.png';
import ElasticEngine from './assets/svg/elasticsearchengine.png';
import CouchDB from './assets/svg/couchdb.png';
import MSsql from './assets/svg/mssql.png';
import Oracle from './assets/svg/Oracle_logo.svg';

import Linux from './assets/svg/linux.png';
import AWS from './assets/svg/AWS.png';
import GoogleCloud from './assets/svg/google cloud.png';
import Docker from './assets/svg/docker.svg';
import Azure from './assets/svg/azure.svg';

import './technologies.scss';


export default class Technologies extends React.Component {

    constructor() {
        super();
        this.state = {
            // switching between open positions (tabbed navigation)
            activeTabIndex: 1,
        }
    }

    _toggleActiveTab = (activeTabIndex) => {

        this.setState({
            activeTabIndex
        });

    }

    render() {

        return(

            <div className="tabContainer">
            
                <div className="tabTitles">
                    <ul>
                        <li onClick={() => this._toggleActiveTab(1)} className={this.state.activeTabIndex === 1 ? 'active' : null} >
                            <img src={WebDesignIcon} alt=""/>
                            <span>Web Design</span>
                        </li>
                        <li onClick={() => this._toggleActiveTab(2)} className={this.state.activeTabIndex === 2 ? 'active' : null} >
                            <img src={WebDevelopmentIcon} alt=""/>
                            <span>Web development</span>
                        </li>
                        <li onClick={() => this._toggleActiveTab(3)} className={this.state.activeTabIndex === 3 ? 'active' : null} >
                            <img src={MobileDevelopmentIcon} alt=""/>
                            <span>Mobile Development</span>
                        </li>
                        <li onClick={() => this._toggleActiveTab(4)} className={this.state.activeTabIndex === 4 ? 'active' : null} >
                            <img src={DatabaseIcon} alt=""/>
                            <span>Database</span>
                        </li>
                        <li onClick={() => this._toggleActiveTab(5)} className={this.state.activeTabIndex === 5 ? 'active' : null} >
                            <img src={ServerIcon} alt=""/>
                            <span>Server</span>
                        </li>
                    </ul>
                </div>
        
                <div className="tabContents">
                
                    <div className={`tabContent ${this.state.activeTabIndex === 1 ? 'active' : null}`} data-content-of="webTechnologies">
                        <h3>Website Design</h3>
                        <div>
                            <img src={ReactLogo} alt="REACT JS"/>
                            <img src={VueLogo} alt="VUE JS"/>
                            <img src={AngularLogo} alt="ANGULAR JS"/>
                            <img src={CSS3Logo} alt="CSS3"/>
                            <img src={SassLogo} alt="SASS"/>
                            <img src={LessLogo} alt="LESS"/>
                            <img src={HTML5Logo} alt="HTML5"/>
                            <img src={JavaScriptLogo} alt="JAVASCRIPT"/>
                            <img src={JqueryLogo} alt="JQUERY"/>
                        </div>
                    </div>
                    <div className={`tabContent ${this.state.activeTabIndex === 2 ? 'active' : null}`}>
                        <h3>Website Development</h3>
                        <div>
                            <img src={Nodejs} alt="Nodejs"/>
                            <img src={Php} alt="Php"/>
                            <img src={Python} alt="Python"/>
                            <img src={Ruby} alt="Ruby"/>
                            <img src={RubyonRails} alt="Rubyonrails"/>
                            <img src={Java} alt="Java"/>
                            <img src={Cpp} alt="C++"/>
                            <img src={Csharp} alt="C#"/>
                        </div>
                    </div>
                    <div className={`tabContent ${this.state.activeTabIndex === 3 ? 'active' : null}`}>
                        <h3>Mobile App Development</h3>
                        <div>
                            <img src={JavaScriptLogo} alt="JAVASCRIPT"/>
                            <img src={Reactnative} alt="Reactnative"/>
                            <img src={NativeScript} alt="NativeScript"/>
                            <img src={Andriod} alt="Andriod"/>
                            <img src={Swift} alt="Swift"/>
                        </div>
                    </div>
                    <div className={`tabContent ${this.state.activeTabIndex === 4 ? 'active' : null}`}>
                        <h3>Database Management</h3>
                        <div>
                            <img src={Oracle} alt="Oracle"/>
                            <img src={PostgreSQL} alt="PostgreSQL"/>
                            <img src={Mysql} alt="Mysql"/>
                            <img src={MongoDB} alt="MongoDB"/>
                            <img src={Neo4j} alt="Neo4j"/>
                            <img src={MSsql} alt="MSsql"/>
                            <img src={ElasticEngine} alt="ElasticEngine"/>
                            <img src={CouchDB} alt="CouchDB"/>
                           
                            
                        </div>
                    </div>
                    <div className={`tabContent ${this.state.activeTabIndex === 5 ? 'active' : null}`}>
                        <h3>Server Administration</h3>
                        <div>
                            <img src={Linux} alt="Linux"/>
                            <img src={AWS} alt="AWS"/>
                            <img src={GoogleCloud} alt="Google Cloud"/>
                            <img src={Docker} alt="Docker"/>
                            <img src={Azure} alt="Azure"/>
                        </div>
                    </div>
        
                </div>
        
            </div>

        );

    }

}

